import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
	PeopleBackendService,
	Person,
} from 'src/app/services/people-backend.service';
import { phoneCountryCodeList } from 'src/app/services/phoneCodes';
import { userType } from 'src/app/setup/httpTypes';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-add-person-dialog',
	templateUrl: './add-person-dialog.component.html',
	styleUrls: ['./add-person-dialog.component.css'],
})
export class AddPersonDialogComponent {
	personFrom = this.fb.group({
		firstName: ['', [Validators.required]],
		lastName: ['', [Validators.required]],
		email: [
			'',
			[Validators.required, Validators.email],
			//[this.personBackend.validEmail()],
		],
		phoneCountryCode: ['+44', [Validators.required]],
		phoneInCountryNumber: [
			'',
			[Validators.required, Validators.pattern('^[1-9]\\d{9}')],
		],
		company: [''],
	});
	saving: boolean = false;
	phoneCountryCodes = phoneCountryCodeList;
	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			title: string;
			description?: string;
			chainId: string;
			buyerSellerId?: string;
			taskId?: string;
			userType: userType;
			propertyId?: string;
		},
		public fb: FormBuilder,
		public personBackend: PeopleBackendService,
		public dialogRef: MatDialogRef<AddPersonDialogComponent>
	) {}

	ngOnInit(): void {
		this.personFrom = this.fb.group({
			firstName: [environment.firstName || '', [Validators.required]],
			lastName: [environment.lastName || '', [Validators.required]],
			email: [
				environment.email || '',
				[Validators.required, Validators.email],
				//[this.personBackend.validEmail()],
			],
			phoneCountryCode: ['+44', [Validators.required]],
			phoneInCountryNumber: [
				'',
				[Validators.required, Validators.pattern('^[1-9]\\d{9}')],
			],
			company: [''],
		});
	}

	async save() {
		this.saving = true;
		//this.personFrom.disable();
		let newPerson: Partial<Person> = this.personFrom.value as any;
		newPerson.chainId = this.data.chainId;
		if (this.data.buyerSellerId)
			newPerson.buyerSellerId = this.data.buyerSellerId;
		if (this.data.taskId) newPerson.taskId = this.data.taskId;
		newPerson.userType = this.data.userType;
		if (this.data.propertyId) newPerson.propertyId = this.data.propertyId;
		console.log(newPerson);
		try {
			this.personFrom.disable();
			let r = await this.personBackend.put(newPerson);

			if (r) {
				this.dialogRef.close(r.data);
			}
		} catch (error) {}

		this.personFrom.enable();
		//this.personFrom.get('email')?.disable();
		this.saving = false;
	}
}
