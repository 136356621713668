import {
	Component,
	EventEmitter,
	Output,
	ChangeDetectorRef,
} from '@angular/core';
import {
	AbstractControl,
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import {
	atLeastOneRequired,
	requiredIfOtherFieldHasValue,
} from 'src/app/form-fields/form-validators';
import { ConfirmExitFrom } from 'src/app/services/forms.service';
import { PeopleBackendService } from 'src/app/services/people-backend.service';
import { Unsubscribe } from 'src/app/services/unsubscribe';
import { environment } from 'src/environments/environment';

type booleanString = 'Y' | 'N' | '' | null | undefined;

@Component({
	selector: 'app-conveyancer',
	templateUrl: './conveyancer.component.html',
	styleUrls: ['./conveyancer.component.css'],
})
export class ConveyancerComponent {
	@Output() data = new EventEmitter();
	@Output() submit = new EventEmitter();
	@Output() back = new EventEmitter();

	@ConfirmExitFrom() clientInfoForm = this.fb.group<{
		clients: FormArray<any>;
	}>({
		clients: this.buildPersonFormArray(),
	});

	@ConfirmExitFrom() purchaseInfoForm = this.fb.group<{
		buyingPropertyYN: FormControl<booleanString>;
		acceptedOfferYN: FormControl<booleanString>;
		address?: FormGroup<any>;
		sellingAgent?: FormArray<any>;
		sellers?: FormArray<any>;
		sellersConveyancer?: FormArray<any>;
	}>(
		{
			buyingPropertyYN: this.fb.control('', [Validators.required]),
			acceptedOfferYN: this.fb.control('', [
				requiredIfOtherFieldHasValue('buyingPropertyYN', 'Y'),
			]),
		},
		{ validators: [this.buyingOrSellingValidator()] }
	);

	get buyingPropertyYN() {
		return this.purchaseInfoForm?.get('buyingPropertyYN');
	}

	get acceptedOfferYN() {
		return this.purchaseInfoForm?.get('acceptedOfferYN');
	}

	@Unsubscribe() buyingPropertyYN$: any;
	@Unsubscribe() acceptedOfferYN$: any;
	@Unsubscribe() purchaseInfoForm$: any;

	@ConfirmExitFrom() sellingInfoForm = this.fb.group<{
		sellingPropertyYN: FormControl<string | null>;
		acceptedOfferYN: FormControl<string | null>;
		dateOfferAccepted: FormControl<string | null>;
		agreedSellingPrice: FormControl<number | null>;
		address?: FormGroup<any>;
		sellingAgent?: FormArray<any>;
		buyers?: FormArray<any>;
		buyersConveyancer?: FormArray<any>;
	}>(
		{
			sellingPropertyYN: this.fb.control('', [Validators.required]),
			acceptedOfferYN: this.fb.control('', [
				requiredIfOtherFieldHasValue('sellingPropertyYN', 'Y'),
			]),
			dateOfferAccepted: this.fb.control('', [
				requiredIfOtherFieldHasValue('acceptedOfferYN', 'Y'),
			]),
			agreedSellingPrice: this.fb.control(null, [
				requiredIfOtherFieldHasValue('acceptedOfferYN', 'Y'),
			]),
		},
		{ validators: [this.buyingOrSellingValidator()] }
	);

	get sellingPropertyYN() {
		return this.sellingInfoForm?.get('sellingPropertyYN');
	}

	get sellingAcceptedOfferYN() {
		return this.sellingInfoForm?.get('acceptedOfferYN');
	}

	@Unsubscribe() sellingPropertyYN$: any;
	@Unsubscribe() agreedSellingPrice$: any;
	@Unsubscribe() sellingInfoForm$: any;

	constructor(
		public fb: FormBuilder,
		public cd: ChangeDetectorRef,
		public peopleBackend: PeopleBackendService
	) {}

	ngOnInit(): void {
		this.buyingPropertyYN$ = this.buyingPropertyYN?.valueChanges.subscribe(
			(value) => {
				if (value == 'N') {
					this.acceptedOfferYN?.setValue(null);
				}
				this.sellingInfoForm.updateValueAndValidity();
				this.cd.detectChanges();
			}
		);

		this.acceptedOfferYN$ = this.acceptedOfferYN?.valueChanges.subscribe(
			(value) => {
				if (value == 'Y') {
					if (!this.purchaseInfoForm.get('sellingAgent'))
						this.purchaseInfoForm.addControl(
							'sellingAgent',
							this.buildPersonFormArray()
						);
					if (!this.purchaseInfoForm.get('address'))
						this.purchaseInfoForm.addControl(
							'address',
							this.buildAddressForm()
						);
					if (!this.purchaseInfoForm.get('sellers'))
						this.purchaseInfoForm.addControl('sellers', this.fb.array([]));
					if (!this.purchaseInfoForm.get('sellersConveyancer'))
						this.purchaseInfoForm.addControl(
							'sellersConveyancer',
							this.fb.array([])
						);
				} else if (value == 'N' || value == null) {
					if (this.purchaseInfoForm.get('sellingAgent'))
						this.purchaseInfoForm.removeControl('sellingAgent');
					if (this.purchaseInfoForm.get('address'))
						this.purchaseInfoForm.removeControl('address');
					if (this.purchaseInfoForm.get('sellers'))
						this.purchaseInfoForm.removeControl('sellers');
					if (this.purchaseInfoForm.get('sellersConveyancer'))
						this.purchaseInfoForm.removeControl('sellersConveyancer');
				}

				this.cd.detectChanges();
			}
		);

		this.purchaseInfoForm$ = this.purchaseInfoForm?.valueChanges.subscribe(() =>
			this.submitBuild()
		);

		this.sellingPropertyYN$ = this.sellingPropertyYN?.valueChanges.subscribe(
			(value) => {
				if (value == 'Y') {
					if (!this.sellingInfoForm.get('sellingAgent'))
						this.sellingInfoForm.addControl(
							'sellingAgent',
							this.buildPersonFormArray()
						);
					if (!this.sellingInfoForm.get('address'))
						this.sellingInfoForm.addControl('address', this.buildAddressForm());
					if (!this.sellingInfoForm.get('buyers'))
						this.sellingInfoForm.addControl('buyers', this.fb.array([]));
					if (!this.sellingInfoForm.get('buyersConveyancer'))
						this.sellingInfoForm.addControl(
							'buyersConveyancer',
							this.fb.array([])
						);
				} else if (value == 'N' || value == null) {
					if (this.sellingInfoForm.get('sellingAgent'))
						this.sellingInfoForm.removeControl('sellingAgent');
					if (this.sellingInfoForm.get('address'))
						this.sellingInfoForm.removeControl('address');
					if (this.sellingInfoForm.get('buyers'))
						this.sellingInfoForm.removeControl('buyers');
					if (this.sellingInfoForm.get('buyersConveyancer'))
						this.sellingInfoForm.removeControl('buyersConveyancer');
				}

				this.cd.detectChanges();
			}
		);

		this.sellingInfoForm$ = this.sellingAcceptedOfferYN?.valueChanges.subscribe(
			() => this.submitBuild()
		);
	}

	submitForm() {
		if (
			this.clientInfoForm.valid &&
			this.purchaseInfoForm.valid &&
			this.sellingInfoForm.valid
		) {
			this.submitBuild();
			this.submit.emit();
		}
	}

	submitBuild() {
		if (
			this.clientInfoForm.valid &&
			this.purchaseInfoForm.valid &&
			this.sellingInfoForm.valid
		) {
			let conveyancer_Data = {
				...this.clientInfoForm.value,
				buying: this.purchaseInfoForm.value,
				selling: this.sellingInfoForm.value,
			};

			this.clientInfoForm.markAsPristine();
			this.purchaseInfoForm.markAsPristine();
			this.sellingInfoForm.markAsPristine();

			this.data.emit(conveyancer_Data);
		} else {
			this.data.emit(null);
		}
	}

	buyingOrSellingValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (
				this.buyingPropertyYN?.value == 'N' &&
				this.sellingPropertyYN?.value == 'N'
			) {
				return { buyingOrSelling: true };
			}
			return null;
		};
	}

	buildAddressForm() {
		return this.fb.group({
			postcode: [environment.postcode, [Validators.required]],
			address: ['', [Validators.required]],
			addressline1: [null],
			addressline2: [null],
			number: [null],
			premise: [null],
			street: [null],
			posttown: [null],
			county: [null],
			UPRN: ['', [Validators.required]],
			longitude: [null],
			latitude: [null],
			usrn: [null],
		});
	}

	buildPersonFormArray(_atLeastOneRequired = true) {
		let validators = [atLeastOneRequired];
		if (!_atLeastOneRequired) validators = [];
		return this.fb.array(
			[
				this.fb.group({
					firstName: [environment.firstName, [Validators.required]],
					lastName: [environment.lastName, [Validators.required]],
					email: [
						environment.email,
						[Validators.required, Validators.email],
						//[this.peopleBackend.validEmail()],
					],
					phoneCountryCode: ['+44', [Validators.required]],
					phoneInCountryNumber: [
						'',
						[Validators.required, Validators.pattern('^[1-9]\\d{9}')],
					],
					company: [null],
				}),
			],
			validators
		);
	}
}
