<!-- <mat-spinner *ngIf="loading"></mat-spinner> -->
<!-- <h2 *ngIf="!loading" class="title">{{property.address}}</h2> -->

<mat-card
	appearance="outlined"
	*ngIf="!loading"
	[@inOutAnimation]
	class="details-card">
	<mat-card-header>
		<!-- <h3> -->
		<mat-card-title>Property Details</mat-card-title>

		<!-- <span *ngIf="!propertyInfoOpen"> ({{ property.nickName }}) </span>
			</h3> -->
		<!-- <div class="spacer"></div> -->
		<!-- <div>
				<button mat-icon-button (click)="propertyInfoOpen = !propertyInfoOpen">
					<mat-icon>{{
						propertyInfoOpen ? "expand_less" : "expand_more"
					}}</mat-icon>
				</button>

			</div> -->
	</mat-card-header>
	<mat-card-content>
		<form
			[formGroup]="addressFormGroup"
			(ngSubmit)="saveAddressForm()"
			class="address-form">
			<mat-form-field appearance="outline" style="width: 55%; flex-grow: 1">
				<mat-label>Property Nick Name</mat-label>
				<mat-select formControlName="nickName">
					<mat-option *ngFor="let name of nicknames" [value]="name.name">
						{{ name.name }}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-icon
				#nnToolTip="matTooltip"
				(click)="nnToolTip.toggle()"
				matTooltip="This is the public name you give to the property you’re selling. It is a way of differentiating your property without giving away sensitive information to the rest of the chain."
				>info</mat-icon
			>
			<mat-form-field appearance="outline" style="width: 29%">
				<mat-label>Postcode</mat-label>
				<input matInput placeholder="Post Code" formControlName="postCode" />
			</mat-form-field>

			<mat-form-field appearance="outline" style="width: 100%">
				<mat-label>Full Address</mat-label>
				<input matInput placeholder="Full Address" formControlName="address" />
			</mat-form-field>
			<app-group-title
				title="Title Details"
				*ngIf="property.titles && property.titles.length > 0">
				<div *ngFor="let title of property.titles || []; let i = index">
					<div>
						<div style="margin-left: 0.5rem; color: gray">
							Title Number: {{ title.number }}
						</div>
						<div style="margin-left: 0.5rem; color: gray">
							Tenure: {{ title.ownerShipType == 0 ? "Solo" : "Joint" }}
							{{ title.tenure }}
						</div>
						<div *ngFor="let doc of title.documents || []; let i = index">
							<button
								mat-button
								(click)="openDocument(doc)"
								[disabled]="!userIsOnProperty()">
								{{ doc.name }}
							</button>
						</div>
					</div>
				</div>
				<div style="margin-left: 0.5rem; color: gray; font-size: 0.5rem">
					Title Info from the Owner Verification Task under the Contract
					Milestone.
				</div>
			</app-group-title>
			<app-group-title
				title="Material Information"
				*ngIf="userIsAgent() || userIsSeller() || property.postedMaterialId">
				<div style="display: flex; justify-content: space-between">
					<div></div>
					<button
						*ngIf="property.postedMaterialId"
						mat-raised-button
						color="primary"
						(click)="openMaterialInfo()">
						Open
					</button>
					<button
						mat-button
						*ngIf="property.postedMaterialId"
						(click)="copyToClipboardMaterialInfoURL()">
						Copy URL
					</button>
					<button
						mat-raised-button
						*ngIf="userIsAgent() || userIsSeller()"
						(click)="editMaterialInfo()">
						{{
							property.savedMaterialId ? "Edit" : "Create Material Information"
						}}
					</button>
					<div></div>
				</div>
			</app-group-title>

			<div class="actions">
				<!-- <button
					type="button"
					[disabled]="loading || addressFormGroup.disabled"
					mat-button
					(click)="sendOfferRequest()">
					Send Offer Request
				</button> -->
				<button
					type="button"
					[disabled]="
						addressFormGroup.pristine || loading || addressFormGroup.disabled
					"
					mat-button
					color=""
					(click)="getProperty()">
					Reset
				</button>
				<button
					class="save-button"
					[disabled]="
						addressFormGroup.pristine ||
						loading ||
						addressFormGroup.disabled ||
						addressFormGroup.invalid
					"
					mat-raised-button
					color="primary"
					type="submit">
					{{ loading ? "Saving..." : "Save" }}
				</button>
			</div>
		</form>
	</mat-card-content>
</mat-card>

<mat-card
	appearance="outlined"
	*ngIf="!loading"
	[@inOutAnimation]
	class="access-card">
	<mat-card-header>
		<mat-card-title>
			User Access Requests ({{ requests.length }})

			<!-- <div>
				<button mat-icon-button (click)="requestsOpen = !requestsOpen">
					<mat-icon>{{
						requestsOpen ? "expand_less" : "expand_more"
					}}</mat-icon>
				</button>
			</div> -->
		</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<app-request-list
			[requests]="requests"
			[loading]="loadingRequests"></app-request-list>
	</mat-card-content>
</mat-card>

<mat-card
	appearance="outlined"
	*ngIf="!loading && userIsAgent() && !memorandumOfSaleCompleted()"
	[@inOutAnimation]
	class="milestones-card">
	<mat-card-header>
		<mat-card-title>
			Offer Requests ({{ property.offers?.length }})
		</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<div
			*ngFor="let offerR of property.offers; let i = index"
			style="display: flex">
			<div style="width: 33%">{{ offerR.firstName }} {{ offerR.lastName }}</div>
			<div style="width: 33%; flex-grow: 1">
				{{ offerR.email }}
			</div>
			<div style="flex-shrink: 1">
				{{ offerR.status }}
			</div>
		</div>
		<div class="actions">
			<button
				type="button"
				[disabled]="loading || addressFormGroup.disabled"
				mat-button
				(click)="sendOfferRequest()">
				Send Offer Request
			</button>
		</div>
	</mat-card-content>
</mat-card>

<mat-card
	appearance="outlined"
	*ngIf="!loading"
	[@inOutAnimation]
	class="milestones-card">
	<mat-card-header>
		<mat-card-title>
			Milestones ({{ taskList.length }})

			<!-- <div>
				<button mat-icon-button (click)="milestonesOpen = !milestonesOpen">
					<mat-icon>{{
						milestonesOpen ? "expand_less" : "expand_more"
					}}</mat-icon>
				</button>
			</div> -->
		</mat-card-title>
	</mat-card-header>
	<mat-card-content *ngIf="milestonesOpen">
		<app-task-lists
			[taskList]="taskList"
			(taskChanged)="getTasks()"
			[defaultHideShow]="true"></app-task-lists>
	</mat-card-content>
</mat-card>

<app-people-form
	class="people-card"
	*ngIf="!loading"
	title="Estate Agent"
	[propertyId]="propertyId"
	[chainId]="property.chainId"
	[userType]="userType.sellersAgent"
	[AtLeastOne]="false"
	[state]="'inProgress'"
	[deleteFromEverywhere]="true">
</app-people-form>

<app-people-form
	class="people-card"
	*ngIf="!loading"
	title="Buyers Conveyancer"
	[propertyId]="propertyId"
	[chainId]="property.chainId"
	[userType]="userType.BuyersConveyancer"
	[AtLeastOne]="false"
	[state]="'inProgress'"
	[deleteFromEverywhere]="true">
</app-people-form>

<app-people-form
	class="people-card"
	*ngIf="!loading"
	title="Seller Conveyancer"
	[propertyId]="propertyId"
	[chainId]="property.chainId"
	[userType]="userType.SellersConveyancer"
	[AtLeastOne]="false"
	[state]="'inProgress'"
	[deleteFromEverywhere]="true">
</app-people-form>

<app-people-form
	class="people-card"
	*ngIf="!loading"
	title="Mortgage Broker"
	[propertyId]="propertyId"
	[chainId]="property.chainId"
	[userType]="userType.mortgageBroker"
	[AtLeastOne]="false"
	[state]="'inProgress'"
	[deleteFromEverywhere]="true">
</app-people-form>

<div style="height: 10rem; width: 100%"></div>

<app-loading-tombstone
	*ngIf="loading"
	[morePadding]="true"></app-loading-tombstone>
