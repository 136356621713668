import {
	Component,
	EventEmitter,
	HostBinding,
	HostListener,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { SwiperComponent } from 'src/app/form-fields/swiper/swiper.component';
import {
	TaskResult,
	TaskStatus,
	TaskType,
	Task,
} from 'src/app/services/task-backend.service';

@Component({
	selector: 'app-task-item',
	templateUrl: './task-item.component.html',
	styleUrls: ['./task-item.component.css'],
})
export class TaskItemComponent {
	@Input() state: TaskStatus = TaskStatus.inProgress;
	_task: Partial<Task> = {};
	@Input() set task(val: Partial<Task>) {
		this._task = val;
		this.pointer = this._task?.canBeOpened || false;
	}
	get task(): Partial<Task> {
		return this._task;
	}
	@Output() markAsComplete = new EventEmitter<any>();
	@Output() markAsInprogress = new EventEmitter<any>();
	@Output() markAsNotStarted = new EventEmitter<any>();
	@Output() open = new EventEmitter<any>();
	@ViewChild('swiper') swiper?: SwiperComponent;
	TaskStatus = TaskStatus;
	TaskResult = TaskResult;
	TaskType = TaskType;

	taskInProgressCount(task: Partial<Task>) {
		if (!task.children || task.children.length < 1) return 0;
		let total = task.children.reduce((acc, cTask) => {
			return acc + (cTask.status == TaskStatus.inProgress ? 1 : 0);
		}, 0);
		return total;
	}

	taskCompletedCount(task: Partial<Task>) {
		if (!task.children || task.children.length < 1) return 0;
		let total = task.children.reduce((acc, cTask) => {
			return acc + (cTask.status == TaskStatus.completed ? 1 : 0);
		}, 0);
		return total;
	}

	taskNotStartedCount(task: Partial<Task>) {
		if (!task.children || task.children.length < 1) return 0;
		let total = task.children.reduce((acc, cTask) => {
			return acc + (cTask.status == TaskStatus.notStarted ? 1 : 0);
		}, 0);
		return total;
	}

	taskDisabledCount(task: Partial<Task>) {
		if (!task.children || task.children.length < 1) return 0;
		let total = task.children.reduce((acc, cTask) => {
			return acc + (cTask.status == TaskStatus.disabled ? 1 : 0);
		}, 0);
		return total;
	}

	taskResultDetails(task: Partial<Task>) {
		if (task.taskType != TaskType.decider) return;
		let r = task.options?.find((o) => o.value == task.result);
		if (!r) return;
		return r.description || r.label;
	}

	left() {
		//console.log('left', this.task.status);
		if (this.task.status == TaskStatus.inProgress) this.markAsComplete.emit();
		if (this.task.status == TaskStatus.notStarted) this.markAsInprogress.emit();
	}

	right() {
		//console.log('right', this.task.status);
		if (this.task.status == TaskStatus.inProgress) this.markAsNotStarted.emit();
	}

	@HostListener('click') click() {
		if (!this.swiper?.inMotion && this.task.canBeOpened) this.open.emit();
	}

	@HostBinding('class.pointer') pointer: boolean = false;

	stateTooltip() {
		let type = this.task.taskType == TaskType.milestone ? 'Milestone' : 'Task';

		if (this.task.status == TaskStatus.inProgress)
			return `This ${type} is in progress, ${
				this.task.hasChildren
					? `You have tasks within this ${type} that are completed or also in progress.`
					: 'It has been started but not completed.'
			}`;
		if (this.task.status == TaskStatus.notStarted)
			return `This ${type} has not been started, ${
				this.task.hasChildren
					? `You have tasks within this ${type} that are ready to be started.`
					: ''
			}`;
		if (this.task.status == TaskStatus.completed)
			return `This ${type} has been completed, ${
				this.task.hasChildren
					? `All of the tasks within this ${type} have also been completed`
					: ''
			}`;
		if (this.task.status == TaskStatus.disabled)
			return `This ${type} is currently blocked by other tasks.  As they are completed this ${type} will become available.  You can also force this ${type} to be available if needed.`;
		if (this.task.status == TaskStatus.cancelled)
			return `This ${type} has been deleted.  You can re-enable it if needed.`;
		return `Click to open the ${type}.`;
	}

	getTimeUntilDate(date: Date | any) {
		date = new Date(date);
		const now = new Date();
		const timeDiff = date.getTime() - now.getTime();
		const days = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
		if (days < 0) return `${Math.abs(days)} day${days < -1 ? 's' : ''} ago`;
		if (days == 0) return `today`;
		if (days < 7) {
			return `in ${days} day${days > 1 ? 's' : ''}`;
		} else {
			const weeks = Math.round(days / 7);
			if (weeks == 1) return `in about a week`;
			return `in about ${weeks} weeks`;
		}
	}

	isDateInTheFuture(date: Date | any) {
		date = new Date(date);
		const now = new Date();
		const timeDiff = date.getTime() - now.getTime();
		if (timeDiff >= 0) return true;
		return false;
	}
}
