import { Injectable } from '@angular/core';
import { userType } from '../setup/httpTypes';
import { backendResponse, BackendService } from './backend.service';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { db } from 'src/app/services/db.service';
import {
	AbstractControl,
	AsyncValidatorFn,
	ValidationErrors,
} from '@angular/forms';

@Injectable({
	providedIn: 'root',
})
export class PeopleBackendService {
	constructor(public backend: BackendService, public Http: HttpClient) {}

	async getOne(personId: string): Promise<backendResponse<Person>> {
		return (await this.backend.get(
			`person/${personId}`
		)) as backendResponse<Person>;
	}

	async getList(options?: {
		chainId?: string;
		propertyId?: string;
		buyerSellerId?: string;
		taskId?: string;
		userType?: userType;
	}): Promise<backendResponse<Person[]>> {
		let queryString = ``;
		if (options?.chainId) {
			queryString += `?chainId=${options.chainId}`;
		}
		if (options?.propertyId) {
			if (queryString.length == 0) queryString += `?`;
			else queryString += `&`;
			queryString += `propertyId=${options.propertyId}`;
		}
		if (options?.buyerSellerId) {
			if (queryString.length == 0) queryString += `?`;
			else queryString += `&`;
			queryString += `buyerSellerId=${options.buyerSellerId}`;
		}
		if (options?.taskId) {
			if (queryString.length == 0) queryString += `?`;
			else queryString += `&`;
			queryString += `taskId=${options.taskId}`;
		}
		if (options?.userType) {
			if (queryString.length == 0) queryString += `?`;
			else queryString += `&`;
			queryString += `userTypes=${options.userType}`;
		}

		return (await this.backend.get(`persons${queryString}`)) as backendResponse<
			Person[]
		>;
	}

	async put(
		person: Partial<Person>,
		addAtTopLevel: boolean = true
	): Promise<backendResponse<Person>> {
		let queryString = ``;
		if (addAtTopLevel) {
			queryString += `?addAtTopLevel=true`;
		}
		return (await this.backend.put(
			`person${queryString}`,
			person
		)) as backendResponse<Person>;
	}

	async patch(
		personId: string,
		person: Partial<Person>
	): Promise<backendResponse<Person>> {
		return (await this.backend.patch(
			`person/${personId}`,
			person
		)) as backendResponse<Person>;
	}

	async delete(
		personId: string,
		fromEverywhere = false
	): Promise<backendResponse<Person>> {
		let queryString = ``;
		if (fromEverywhere) {
			queryString += `?fromEverywhere=true`;
		}
		return (await this.backend.delete(
			`person/${personId}${queryString}`
		)) as backendResponse<Person>;
	}

	async missingPersons(chainId: string): Promise<
		backendResponse<
			{
				userType: userType;
				propertyId: string;
				propertyAddress: string;
				propertyNickname: string;
			}[]
		>
	> {
		return (await this.backend.get(
			`missingPersons?chainId=${chainId}`
		)) as backendResponse<
			{
				userType: userType;
				propertyId: string;
				propertyAddress: string;
				propertyNickname: string;
			}[]
		>;
	}

	async addPushEndpoint(endPoint: any): Promise<backendResponse<any>> {
		return (await this.backend.put(
			`pushEndpoints`,
			endPoint
		)) as backendResponse<any>;
	}

	async getOfficialSearch(): Promise<backendResponse<boolean>> {
		return (await this.backend.get(
			`officialSearches`
		)) as backendResponse<boolean>;
	}

	async patchOfficialSearch(
		officialSearch: boolean
	): Promise<backendResponse<boolean>> {
		return (await this.backend.patch(`officialSearches`, {
			officialSearch,
		})) as backendResponse<boolean>;
	}

	//https://ws.postcoder.com/pcw/PCWN2-9UYMQ-ZBZ64-ZXMT9/email/rose@clearchain.house?format=json
	async checkEmail(email: string): Promise<boolean> {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!email) return false;
		if (!emailRegex.test(email)) return false;
		let cash = await db.emailVerification.get(email);
		if (cash) {
			return cash.valid;
		}
		let r = await (
			lastValueFrom(
				this.Http.get(
					`https://ws.postcoder.com/pcw/PCWN2-9UYMQ-ZBZ64-ZXMT9/email/${email}?format=json`
				)
			) as Promise<undefined | { valid: boolean }>
		)
			.then((r) => {
				if (r)
					db.emailVerification.put({ email: email, valid: r?.valid || false });
				return r;
			})
			.catch((e) => {
				console.error(e);
				return {
					valid: true,
				};
			});
		return r?.valid || false;
	}

	validEmail(): AsyncValidatorFn {
		return (control: AbstractControl): Promise<ValidationErrors | null> => {
			return this.checkEmail(control.value).then((r) => {
				return r ? null : { invalidEmail: true };
			});
		};
	}

	async unsubscribe(email: string): Promise<backendResponse<any>> {
		return (await this.backend.put(
			`unsubscribe`,
			{
				userEmail: email,
			},
			{
				noCredentials: true,
			}
		)) as backendResponse<any>;
	}
}

export interface Person {
	id: string;
	firstName?: string;
	lastName?: string;
	email: string;
	phone?: string;
	phoneCountryCode?: string;
	phoneInCountryNumber?: string;
	company?: string;
	userType: userType;
	companyId?: string;
	chainId: string;
	taskId?: string;
	propertyId?: string;
	buyerSellerId?: string;
	userCreated?: string;
	emailSent?: number;
	lastOpened?: number;
	setupComplete?: Boolean;
	createdAt?: number;
	updatedAt?: number;
	ccEmail?: string;
	verified?: boolean;
}
