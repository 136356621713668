<h1 mat-dialog-title>Add {{ data.title }}</h1>
<mat-dialog-content
	style="
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		max-width: 600px;
		padding: 1rem;
		gap: 0 1rem;
	"
	[formGroup]="personFrom">
	<div *ngIf="data.description" style="margin-bottom: 3rem">
		{{ data.description }}
	</div>
	<mat-form-field
		appearance="outline"
		floatLabel="always"
		style="min-width: 40%">
		<mat-label>First Name</mat-label>
		<input matInput placeholder="First Name" formControlName="firstName" />
	</mat-form-field>
	<mat-form-field appearance="outline" floatLabel="always">
		<mat-label>Last Name</mat-label>
		<input matInput placeholder="Last Name" formControlName="lastName" />
	</mat-form-field>
	<mat-form-field appearance="outline" floatLabel="always" style="width: 100%">
		<mat-label>Company</mat-label>
		<input matInput placeholder="Company" formControlName="company" />
	</mat-form-field>
	<mat-form-field
		appearance="outline"
		floatLabel="always"
		matTooltip="Emails Once added cant be changed.  To edit you must remove the person and add them again with the right email.">
		<mat-label>Email</mat-label>
		<input matInput placeholder="Email" formControlName="email" />
	</mat-form-field>
	<div style="flex-grow: 1; display: flex; gap: 1rem">
		<mat-form-field
			style="width: 6rem"
			appearance="outline"
			floatLabel="always">
			<mat-label>Code</mat-label>
			<mat-select
				style="display: inline; max-width: 3rem"
				value="+44"
				formControlName="phoneCountryCode">
				<mat-option
					style="
						min-width: 8rem;
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;
					"
					*ngFor="let code of phoneCountryCodes; let i = index"
					[value]="code">
					<!-- <img style="width: 2rem" [src]="code.image" /> -->
					{{ code }}
				</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field
			style="width: 9rem"
			appearance="outline"
			floatLabel="always">
			<mat-label> Phone </mat-label>

			<input
				matInput
				appLocalPhoneNumber
				placeholder="1234567890"
				formControlName="phoneInCountryNumber" />
		</mat-form-field>
	</div>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: end">
	<button
		mat-button
		[mat-dialog-close]="false"
		type="button"
		[disabled]="saving">
		Cancel
	</button>
	<button
		mat-button
		(click)="save()"
		[disabled]="
			personFrom.invalid || personFrom.pristine || personFrom.disabled
		"
		type="button"
		cdkFocusInitial>
		<span *ngIf="!saving">Add</span>
		<span *ngIf="saving">Saving<app-loading-dots></app-loading-dots></span>
	</button>
</mat-dialog-actions>
