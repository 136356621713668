import { ChangeDetectorRef, Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Chain } from 'src/app/services/chains-backend.service';
import {
	EAddress,
	Email,
	EmailBackendService,
} from 'src/app/services/email-backend.service';
import { TaskStatus, Task } from 'src/app/services/task-backend.service';

@Component({
	selector: 'app-sort-email',
	templateUrl: './sort-email.component.html',
	styleUrls: ['./sort-email.component.css'],
})
export class SortEmailComponent {
	emailId: string = this.route.snapshot.params['emailId'];
	email: Email | undefined;
	$prams: any;
	loading = false;
	chainId?: string;
	propertyId?: string;

	taskStatus = TaskStatus;

	chain?: Chain | undefined;
	chains: Chain[] = [];
	constructor(
		public route: ActivatedRoute,
		public emailBackend: EmailBackendService,
		public sanitizer: DomSanitizer,
		public Auth: AuthService,
		public cd: ChangeDetectorRef
	) {}

	async ngOnInit() {
		this.route.params.subscribe(async (params) => {
			this.loading = true;
			this.emailId = params['emailId'];
			let emailR = await this.emailBackend.getAdmin(this.emailId);
			this.email = emailR.data;
			if (this.email && this.email.html)
				this.email.html = (
					this.sanitizer.bypassSecurityTrustHtml(this.email.html || '') as any
				)['changingThisBreaksApplicationSecurity'];
			if (
				countUnique(
					this.email?.posableProperties?.map((p: any) => p.chainId) || []
				) == 1
			) {
				this.chainId = this.email?.posableProperties?.[0]?.chainId;
				let chainData = await this.emailBackend.getAdminChain(
					this.chainId || ''
				);
				this.chain = chainData.data;
			}
			this.loading = false;
			let chainIds = Array.from(
				new Set((this.email.properties || []).map((p) => p.chainId))
			);
			for (let chainId of chainIds) {
				let chainData = await this.emailBackend.getAdminChain(chainId);
				this.chains.push(chainData.data);
			}
			this.cd.detectChanges();
		});
	}

	ngOnDestroy() {
		if (this.$prams) this.$prams.unsubscribe();
	}

	resizeIframe(event: any) {
		event.srcElement.style.height =
			event.srcElement.contentWindow.document.body.scrollHeight + 25 + 'px';
	}

	propColor(property: any) {
		let color = 'green';
		if (property?.isLate) color = 'yellow';
		if (property?.issueCount > 0) color = 'yellow';
		if (property?.id == 'unknown') color = 'red';
		if (property?.isPlaceHolder) color = 'red';
		if (property?.postCode == 'No Postcode') color = 'red';
		return color;
	}

	logoURL(property: any) {
		let color = this.propColor(property);
		return `/assets/colorIcons/${color}.svg`;
	}

	async topSelectProperty(property: any) {
		this.chainId = property.chainId;
		this.propertyId = property.id;

		let chainData = await this.emailBackend.getAdminChain(this.chainId || '');
		this.chain = chainData.data;
	}

	async taskState(task: Task, state: TaskStatus) {
		let userEmail = this.userThatCanEditTask(task);

		if (userEmail)
			await this.emailBackend.setTaskStatus(task.id, state, userEmail || '');
		// .then((r) => {
		task.status = state;
		(task as any).buttons = false;
		//})
		//.catch((e) => {});
	}

	userThatCanEditTask(task: Task) {
		if (
			task.viewers.some(
				(_v) => _v.canEdit && _v.email == this?.email?.from?.userEmail
			)
		)
			return this?.email?.from?.userEmail;

		let user = this?.email?.to?.find((_to) =>
			task.viewers.some((_v) => _v.canEdit && _v.email == _to.userEmail)
		);

		if (user) return user.userEmail;

		user = this?.email?.cc?.find((_cc) =>
			task.viewers.some((_v) => _v.canEdit && _v.email == _cc.userEmail)
		);

		if (user) return user.userEmail;

		return undefined;
	}

	canEditTask(task: Task) {
		if (
			task.viewers.some(
				(_v) => _v.canEdit && _v.email == this?.email?.from?.userEmail
			)
		)
			return true;

		if (
			this?.email?.to?.some((_to) =>
				task.viewers.some((_v) => _v.canEdit && _v.email == _to.userEmail)
			)
		)
			return true;

		if (
			this?.email?.cc?.some((_cc) =>
				task.viewers.some((_v) => _v.canEdit && _v.email == _cc.userEmail)
			)
		)
			return true;
		return false;
	}

	async setAsSpoof(email: EAddress) {
		let isAdmin = await this.Auth.isAdmin();
		if (!isAdmin) return;
		this.Auth.spoofAs = email.userEmail;
	}

	reRunMLSort() {
		this.emailBackend.reRunMLSort(this.emailId);
	}

	task(_id: string) {
		for (let chain of this.chains) {
			for (let property of (chain.links || []).filter(
				(l) => l.linkType == 'property'
			)) {
				for (let mileStone of property.children || []) {
					if (mileStone.id == _id) return mileStone;
					for (let task of mileStone.children || []) {
						if (task.id == _id) return task;
						for (let subTask of task.children || []) {
							if (subTask.id == _id) return subTask;
						}
					}
				}
			}
		}
	}

	property(_id: string) {
		for (let chain of this.chains) {
			for (let property of (chain.links || []).filter(
				(l) => l.linkType == 'property'
			)) {
				for (let mileStone of property.children || []) {
					if (mileStone.id == _id) {
						return this.email?.properties?.find((p) => p.id == property.id);
					}
					for (let task of mileStone.children || []) {
						if (task.id == _id)
							return this.email?.properties?.find((p) => p.id == property.id);
						for (let subTask of task.children || []) {
							if (subTask.id == _id)
								return this.email?.properties?.find((p) => p.id == property.id);
						}
					}
				}
			}
		}
		return undefined;
	}

	async submitReview() {
		await this.emailBackend.submitReview({
			id: this.emailId,
			...this.email?.mlResults,
		});
	}
}

function countUnique(a: string[] | undefined) {
	if (!a) return 0;
	return new Set(a).size;
}
