<mat-card
	appearance="outlined"
	style="height: 100%; display: flex; flex-direction: column"
	[ngClass]="{
		disabled: state == 'disabled',
		completed: state == 'completed'
	}">
	<mat-card-header>
		<mat-card-title>
			{{ title }}s ({{ people.length }})
			<div>
				<!-- <button
					mat-icon-button
					(click)="copyAllEmails()"
					matTooltip="Copy all emails to clipboard"
					*ngIf="people.length > 1 && !loading && canEdit">
					<mat-icon>copy_all</mat-icon>
				</button>
				<a
					mat-icon-button
					matTooltip="Send Email To All"
					[href]="'mailto:' + allEmailsList()"
					target="_blank"
					*ngIf="people.length > 1 && !loading && canEdit">
					<mat-icon>mail</mat-icon>
				</a> -->
				<button
					mat-icon-button
					[matMenuTriggerFor]="menuHeader"
					[disabled]="people.length < 2 || !canEdit || state == 'disabled'"
					aria-label="menu for all persons">
					<mat-icon>more_vert</mat-icon>
				</button>
				<mat-menu #menuHeader="matMenu">
					<button mat-menu-item (click)="copyAllEmails()">
						<mat-icon>copy_all</mat-icon>
						<span>Copy All Emails To Clipboard</span>
					</button>
					<a
						mat-menu-item
						matTooltip="Send Email To All"
						[href]="'mailto:' + allEmailsList()"
						target="_blank">
						<mat-icon>mail</mat-icon>
						<span>Send An Email To All</span>
					</a>
				</mat-menu>
			</div>

			<!-- <div>
				<button mat-icon-button (click)="toggleForm()">
					<mat-icon>{{ formOpen ? "expand_less" : "expand_more" }}</mat-icon>
				</button>
			</div> -->
		</mat-card-title>
	</mat-card-header>
	<mat-card-content class="card-content">
		<div *ngIf="loading && people.length == 0" class="loading">
			<mat-progress-spinner mode="indeterminate" color="primary" diameter="24">
			</mat-progress-spinner>
		</div>
		<div
			*ngIf="!loading && people.length == 0"
			class="no-people"
			[@inOutAnimation]>
			<p>
				There are no {{ userType }}s attached to this
				<span *ngIf="!!propertyId">property</span
				><span *ngIf="!!buyerSellerId">Buyer/Seller</span>.
			</p>
		</div>
		<div>
			<div class="person" *ngFor="let person of people; let i = index">
				<mat-icon color="primary" class="person-icon">person</mat-icon>
				<div
					class="person-details"
					(click)="copyEmail(person.ccEmail || '')"
					(dblclick)="setSpoof(person.email)"
					[matTooltip]="toolTipText(person.email)">
					<div class="person-top-line">
						<span class="person-verified" *ngIf="person.verified"
							><mat-icon
								style="
									font-size: 1rem;
									position: absolute;
									margin-left: -1rem;
									color: #6094f8;
								"
								>verified</mat-icon
							></span
						>
						<span class="person-name">
							{{ person.firstName }} {{ person.lastName }}
						</span>
						<span
							class="person-company"
							*ngIf="person.company && person.company.length > 0">
							- {{ person.company }}</span
						>
					</div>

					<div class="person-email">
						{{
							person.email.includes("*")
								? "******@********.***"
								: person.ccEmail || person.email
						}}
					</div>
				</div>
				<div style="flex-shrink: 0">
					<button
						mat-icon-button
						[matMenuTriggerFor]="menu"
						aria-label="menu for person"
						[disabled]="
							person.email.includes('*') || !canEdit || state == 'disabled'
						">
						<mat-icon>more_vert</mat-icon>
					</button>
					<mat-menu #menu="matMenu">
						<a
							mat-menu-item
							[href]="'mailto:' + person.ccEmail"
							target="_blank">
							<mat-icon>mail</mat-icon>
							<span>Send An Email</span>
						</a>
						<button
							mat-menu-item
							(click)="copyEmail(person.ccEmail || '')"
							[disabled]="!canEdit || state == 'disabled'">
							<mat-icon>content_copy</mat-icon>
							<span>Copy Email To Clipboard</span>
						</button>
						<button
							mat-menu-item
							(click)="editPerson(i)"
							[disabled]="!canEdit || state == 'disabled'">
							<mat-icon>edit</mat-icon>
							<span>Edit Person</span>
						</button>
						<button
							mat-menu-item
							(click)="removePerson(i)"
							[disabled]="!canEdit || state == 'disabled'">
							<mat-icon>delete</mat-icon>
							<span>Remove Person</span>
						</button>
					</mat-menu>
				</div>
			</div>
		</div>
		<!-- <form
			[formGroup]="peopleForm"
			class="people-form"
			*ngIf="!loading || people.length > 0"
			[@inOutAnimation]>
			<div
				*ngFor="let personForm of peopleControls; let i = index"
				[formGroup]="personForm"
				class="person">
				<mat-form-field
					appearance="outline"
					floatLabel="always"
					style="width: 100%">
					<mat-label>Company</mat-label>
					<input matInput placeholder="Company" formControlName="company" />
				</mat-form-field>
				<mat-form-field
					appearance="outline"
					floatLabel="always"
					style="min-width: 40%">
					<mat-label>First Name</mat-label>
					<input
						matInput
						placeholder="First Name"
						formControlName="firstName" />
				</mat-form-field>
				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label>Last Name</mat-label>
					<input matInput placeholder="Last Name" formControlName="lastName" />
				</mat-form-field>
				<mat-form-field
					appearance="outline"
					floatLabel="always"
					matTooltip="Emails Once added cant be changed.  To edit you must remove the person and add them again with the right email.">
					<mat-label>Email</mat-label>
					<input matInput placeholder="Email" formControlName="email" />
				</mat-form-field>
				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label>Phone Number</mat-label>
					<input
						type="tel"
						matInput
						placeholder="+441234567890"
						formControlName="phone" />
				</mat-form-field>

				<div class="actions">
					<button
						mat-button
						(click)="removePerson(i)"
						[disabled]="personForm.disabled">
						Remove Person
					</button>
				</div>
			</div>
			<div class="actions">
				<button
					mat-button
					[disabled]="
						(peopleForm.pristine || peopleForm.disabled) &&
						removedPeopleIds.length == 0
					"
					(click)="getPeople()">
					Reset
				</button>
				<button
					mat-button
					[disabled]="peopleForm.disabled"
					(click)="addPerson()">
					Add Person
				</button>
				<button
					mat-raised-button
					color="primary"
					[disabled]="
						(peopleForm.pristine ||
							peopleForm.invalid ||
							peopleForm.disabled) &&
						removedPeopleIds.length == 0
					"
					(click)="savePeople()">
					<span *ngIf="!saving">Save</span>
					<span *ngIf="saving">
						Saving<app-loading-dots></app-loading-dots>
					</span>
				</button>
			</div>
		</form> -->
		<div style="flex-grow: 1"></div>
		<div
			style="text-align: center; margin-top: 1rem"
			*ngIf="canEdit && (state == 'notStarted' || state == 'inProgress')">
			<button
				style="width: 100%; max-width: 25rem"
				mat-raised-button
				(click)="addPerson()"
				[disabled]="loading || !canEdit"
				[color]="!loading && people.length == 0 ? 'accent' : 'primary'">
				Add {{ title }}
			</button>
		</div>
	</mat-card-content>
</mat-card>
