import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Query,
	ViewChild,
} from '@angular/core';
import {
	AbstractControl,
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
	PropertiesBackendService,
	Property,
} from 'src/app/services/properties-backend.service';
import {
	EditSpaceComponent,
	spaceData,
} from './edit-space/edit-space.component';
import { lastValueFrom, min, switchMap } from 'rxjs';
import {
	EditWorksComponent,
	workData,
} from './edit-works/edit-works.component';
import { MatStepper } from '@angular/material/stepper';
import { EditHeatComponent, heatData } from './edit-heat/edit-heat.component';
import {
	EditParkingSpotComponent,
	parkingSpotData,
} from './editParkingSpot/editParkingSpot.component';
import {
	EditBikeStorageComponent,
	bikeStorageData,
} from './Edit-Bike-Storage/Edit-Bike-Storage.component';
import {
	EditBuildingIssueComponent,
	buildingIssueData,
} from './edit-building-issue/edit-building-issue.component';
import {
	MaterialInfoBackendService,
	materialInfoData,
} from 'src/app/services/materialInfoBackend.service';
import { environment } from 'src/environments/environment';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { PeopleBackendService } from 'src/app/services/people-backend.service';

@Component({
	selector: 'app-material-form',
	templateUrl: './materialForm.component.html',
	styleUrls: ['./materialForm.component.css'],
})
export class MaterialFormComponent {
	@ViewChild('stepper') stepper?: MatStepper;
	loading = false;
	propertyId?: string = '123';
	property?: Property;
	$prams: any;
	askingPriceForm = this.buildAskingPriceForm();
	tenureForm = this.buildTenureForm();
	leasingTenantForm = this.buildLeasingTenantForm();
	propertyConstructionForm = this.buildPropertyConstructionForm();
	spacesForm = this.buildSpacesForm();
	knownWorksForm = this.buildKnownWorksForm();
	utilitiesForm = this.buildUtilitiesForm();
	parkingBikeStorageForm = this.buildParkingBikeStorageForm();
	buildingSafetyIssuesForm = this.buildBuildingSafetyIssuesForm();
	rightsAndEasementsForm = this.buildRightsAndEasementsForm();
	accessibilityForm = this.buildAccessibilityForm();
	confirmationForm = this.buildConfirmationForm();
	form = this.fb.group({
		propertyId: this.fb.control(this.propertyId, [Validators.required]),
		askingPriceForm: this.askingPriceForm,
		tenureForm: this.tenureForm,
		leasingTenantForm: this.leasingTenantForm,
		propertyConstructionForm: this.propertyConstructionForm,
		spacesForm: this.spacesForm,
		knownWorksForm: this.knownWorksForm,
		utilitiesForm: this.utilitiesForm,
		parkingBikeStorageForm: this.parkingBikeStorageForm,
		buildingSafetyIssuesForm: this.buildingSafetyIssuesForm,
		rightsAndEasementsForm: this.rightsAndEasementsForm,
		accessibilityForm: this.accessibilityForm,
		confirmationForm: this.confirmationForm,
	});
	currentSavedForm?: materialInfoData;

	constructor(
		public route: ActivatedRoute,
		public router: Router,
		public fb: FormBuilder,
		public dialog: MatDialog,
		public cd: ChangeDetectorRef,
		public materialBackend: MaterialInfoBackendService,
		public propertyBackend: PropertiesBackendService,
		public peopleBackend: PeopleBackendService,
		public confirmDialog: ConfirmDialogService
	) {
		if (this.$prams?.unsubscribe) this.$prams.unsubscribe();
		this.$prams = this.route.params
			.pipe(
				switchMap(async (params: any) => {
					this.loading = true;
					this.propertyId = params['propertyId'];

					this.clearForm();

					if (!this.propertyId) return params;

					let currentFormR = await this.materialBackend.getSavedOne(
						this.propertyId || ''
					);
					this.currentSavedForm = currentFormR.data;
					this.setFormValue(this.currentSavedForm);

					// let storedForm = JSON.parse(
					// 	localStorage.getItem('materialForm') || '{}'
					// );
					// if (
					// 	storedForm?.propertyId == this.propertyId &&
					// 	storedForm?.lastUpdate > (this.currentSavedForm?.lastUpdate || 0)
					// ) {
					// 	this.setFormValue(storedForm);
					// } else {
					// 	localStorage.removeItem('materialForm');
					// 	localStorage.removeItem('materialFormStepperIndex');
					// }

					let propertyR = await this.propertyBackend.getOne(this.propertyId);
					this.property = propertyR.data;
					//Solo Leasehold
					if (!this.property?.titles || this.property?.titles.length == 0)
						this.form.controls.tenureForm.patchValue({
							tenure: 'Leasehold',
							ownership: 'Sole',
						});
					else if (
						!!this.property?.titles?.find((t) => t.tenure == 'Leasehold')
					) {
						let ownerShipType =
							this.property?.titles?.find((t) => t.tenure == 'Leasehold')
								?.ownerShipType || 0;

						this.form.controls.tenureForm.patchValue({
							tenure: 'Leasehold',
							ownership: ownerShipType == 1 ? 'Joint' : 'Sole',
						});
					} else {
						let ownerShipType = this.property?.titles[0]?.ownerShipType || 0;
						this.form.controls.tenureForm.patchValue({
							tenure: 'Freehold',
							ownership: ownerShipType == 1 ? 'Joint' : 'Sole',
						});
					}
					this.loading = false;
					return params;
				})
			)
			.subscribe(async (params: any) => {
				this.propertyId = params['propertyId'];
			});
	}

	$form: any;
	ngOnInit() {
		// this.clearForm();
		// let storedForm = JSON.parse(localStorage.getItem('materialForm') || '{}');
		// if (storedForm?.propertyId == this.propertyId) {
		// 	this.setFormValue(storedForm);
		// } else {
		// 	localStorage.removeItem('materialForm');
		// 	localStorage.removeItem('materialFormStepperIndex');
		// }
	}

	clearForm() {
		this.ngOnDestroy();
		this.askingPriceForm = this.buildAskingPriceForm();
		this.tenureForm = this.buildTenureForm();
		this.leasingTenantForm = this.buildLeasingTenantForm();
		this.propertyConstructionForm = this.buildPropertyConstructionForm();
		this.spacesForm = this.buildSpacesForm();
		this.knownWorksForm = this.buildKnownWorksForm();
		this.utilitiesForm = this.buildUtilitiesForm();
		this.parkingBikeStorageForm = this.buildParkingBikeStorageForm();
		this.buildingSafetyIssuesForm = this.buildBuildingSafetyIssuesForm();
		this.rightsAndEasementsForm = this.buildRightsAndEasementsForm();
		this.accessibilityForm = this.buildAccessibilityForm();
		this.confirmationForm = this.buildConfirmationForm();
		this.form = this.fb.group({
			propertyId: this.fb.control(this.propertyId, [Validators.required]),
			askingPriceForm: this.askingPriceForm,
			tenureForm: this.tenureForm,
			leasingTenantForm: this.leasingTenantForm,
			propertyConstructionForm: this.propertyConstructionForm,
			spacesForm: this.spacesForm,
			knownWorksForm: this.knownWorksForm,
			utilitiesForm: this.utilitiesForm,
			parkingBikeStorageForm: this.parkingBikeStorageForm,
			buildingSafetyIssuesForm: this.buildingSafetyIssuesForm,
			rightsAndEasementsForm: this.rightsAndEasementsForm,
			accessibilityForm: this.accessibilityForm,
			confirmationForm: this.confirmationForm,
		});

		this.$form = this.form.valueChanges.subscribe((form) => {
			// let storedForm = JSON.parse(localStorage.getItem('materialForm') || '{}');
			// if(storedForm.lastUpdate > form.lastUpdate) return;
			// localStorage.setItem(
			// 	'materialForm',
			// 	JSON.stringify({ ...form, lastUpdate: Date.now() })
			// );
		});
	}

	setFormValue(data: any = {}) {
		let _data = { ...data, confirmationForm: {} };
		this.form.patchValue(_data);

		//add leasehold agreements
		let agreements = _data?.tenureForm?.leaseholdAgreement || [];
		// this._addNewLeaseholdAgreement({
		// 	id: '',
		// 	name: 'test',
		// 	date: new Date(),
		// });
		while ((this.leaseholdAgreementArray || []).length < agreements.length) {
			this._addNewLeaseholdAgreement({});
		}

		//add spaces
		let spaces = _data?.spacesForm?.spaces || [];
		while ((this.spacesArray || []).length < spaces.length) {
			this._addNewSpace({});
		}

		//add known works
		let knownWorks = _data?.knownWorksForm?.knownWorks || [];
		while ((this.worksArray || []).length < knownWorks.length) {
			this._addNewWork({});
		}

		//add heating sources
		let heatingSources = _data?.utilitiesForm?.heatingSources || [];
		while (
			(this.utilitiesHeatingSources || []).length < heatingSources.length
		) {
			this._addNewHeatingSource({});
		}

		//add parking spots
		let parkingSpots = _data?.parkingBikeStorageForm?.parkingSpots || [];
		while ((this.parkingSpots || []).length < parkingSpots.length) {
			this._addNewParkingSpot({});
		}

		//add bike storage
		let bikeStorage = _data?.parkingBikeStorageForm?.bikeStorage || [];
		while ((this.bikeStorageSpots || []).length < bikeStorage.length) {
			this._addNewBikeStorageSpot({});
		}

		//add building safety issues
		let buildingSafetyIssues = _data?.buildingSafetyIssuesForm?.issues || [];
		while ((this.issuesArray || []).length < buildingSafetyIssues.length) {
			this._addNewIssue({});
		}

		this.form.patchValue(_data);

		if (this.stepper) {
			this.stepper.steps.forEach((s) => {
				if (s.stepControl?.valid) s.completed = true;
				else s.completed = false;
			});
			this.cd.detectChanges();
			let materialFormId: string | undefined = undefined;
			try {
				materialFormId = JSON.parse(
					localStorage.getItem('materialFormId') || 'false'
				);
			} catch (error) {}

			if (this.currentSavedForm?.id == materialFormId) {
				let stepperIndex: string | undefined = undefined;
				try {
					stepperIndex = JSON.parse(
						localStorage.getItem('materialFormStepperIndex') || '0'
					);
				} catch (error) {}

				this.stepper.selectedIndex = stepperIndex || 0;
				this.cd.detectChanges();
			}
		}
	}

	async saveForm() {
		await this.materialBackend.patch(
			this.propertyId || '',
			this.currentSavedForm?.id || '',
			this.form.value as any
		);
		this.form.markAsPristine();
		this.form.markAsUntouched();

		console.log('saved');
		console.log(this.form.value.tenureForm?.leaseholdAgreement);
	}

	$stepperIndex: any;
	ngAfterViewInit() {
		//let storedForm = JSON.parse(localStorage.getItem('materialForm') || '{}');
		if (this.stepper) {
			this.stepper.steps.forEach((s) => {
				if (s.stepControl?.valid) s.completed = true;
			});
			this.cd.detectChanges();

			let materialFormId: string | undefined = undefined;
			try {
				materialFormId = JSON.parse(
					localStorage.getItem('materialFormId') || 'false'
				);
			} catch (error) {}

			if (this.currentSavedForm?.id == materialFormId) {
				let stepperIndex: string | undefined = undefined;
				try {
					stepperIndex = JSON.parse(
						localStorage.getItem('materialFormStepperIndex') || '0'
					);
				} catch (error) {}

				this.stepper.selectedIndex = stepperIndex || 0;
				this.cd.detectChanges();
			}
			if (this.$stepperIndex?.unsubscribe) this.$stepperIndex.unsubscribe();
			this.$stepperIndex = this.stepper.selectionChange.subscribe((s) => {
				localStorage.setItem(
					'materialFormStepperIndex',
					JSON.stringify(s.selectedIndex)
				);
				localStorage.setItem(
					'materialFormId',
					JSON.stringify(this.currentSavedForm?.id)
				);
			});
		}
	}

	ngOnDestroy() {
		if (this.$prams?.unsubscribe) this.$prams.unsubscribe();
		if (this.$askingPriceNewBuild?.unsubscribe)
			this.$askingPriceNewBuild.unsubscribe();
		if (this.$askingPriceReservationRequired?.unsubscribe)
			this.$askingPriceReservationRequired.unsubscribe();
		if (this.$tenure?.unsubscribe) this.$tenure.unsubscribe();
		if (this.$petRestrictions?.unsubscribe) this.$petRestrictions.unsubscribe();
		if (this.$currentLeasingTenant?.unsubscribe)
			this.$currentLeasingTenant.unsubscribe();
		if (this.$leaseBeyondSale?.unsubscribe) this.$leaseBeyondSale.unsubscribe();
		if (this.$propertyType?.unsubscribe) this.$propertyType.unsubscribe();
		if (this.$wallConstruction?.unsubscribe)
			this.$wallConstruction.unsubscribe();
		if (this.$roofType?.unsubscribe) this.$roofType.unsubscribe();
		if (this.$irregularBoundary?.unsubscribe)
			this.$irregularBoundary.unsubscribe();
		if (this.$boundaryMoved?.unsubscribe) this.$boundaryMoved.unsubscribe();
		if (this.$knownWorks?.unsubscribe) this.$knownWorks.unsubscribe();
		if (this.$form?.unsubscribe) this.$form.unsubscribe();
		if (this.$stepperIndex?.unsubscribe) this.$stepperIndex.unsubscribe();
		if (this.$sewerageType?.unsubscribe) this.$sewerageType.unsubscribe();
		if (this.$parkingAvailable?.unsubscribe)
			this.$parkingAvailable.unsubscribe();
		if (this.$bikeStorageAvailable?.unsubscribe)
			this.$bikeStorageAvailable.unsubscribe();
		if (this.$anyKnownIssues?.unsubscribe) this.$anyKnownIssues.unsubscribe();
		if (this.$rightsAndEasements?.unsubscribe)
			this.$rightsAndEasements.unsubscribe();
		if (this.$sinkingFund?.unsubscribe) this.$sinkingFund.unsubscribe();
		if (this.$capitalWorksPlanned?.unsubscribe)
			this.$capitalWorksPlanned.unsubscribe();
		if (this.$hasManagingAgent?.unsubscribe)
			this.$hasManagingAgent.unsubscribe();
	}

	$askingPriceNewBuild: any;
	$askingPriceReservationRequired: any;
	buildAskingPriceForm() {
		let fb = this.fb.group<{
			askingPrice: FormControl<number | null>;
			newBuild: FormControl<boolean | null>;
			reservationRequired?: FormControl<boolean | null>;
			reservationFee?: FormControl<number | null>;
		}>({
			askingPrice: this.fb.control(null, [Validators.required]),
			newBuild: this.fb.control(null, [Validators.required]),
			//reservationRequired: this.fb.control(null, []),
			//reservationFee: this.fb.control(null, []),
		});

		if (this.$askingPriceNewBuild?.unsubscribe)
			this.$askingPriceNewBuild.unsubscribe();
		this.$askingPriceNewBuild = fb
			.get('newBuild')
			?.valueChanges.subscribe((newBuild) => {
				if (newBuild) {
					fb.addControl(
						'reservationRequired',
						this.fb.control(null, [Validators.required])
					);
					if (this.$askingPriceReservationRequired?.unsubscribe)
						this.$askingPriceReservationRequired.unsubscribe();
					this.$askingPriceReservationRequired = fb
						.get('reservationRequired')
						?.valueChanges.subscribe((reservationRequired) => {
							if (reservationRequired) {
								fb.addControl(
									'reservationFee',
									this.fb.control(null, [Validators.required])
								);
							} else {
								fb.removeControl('reservationFee');
							}
						});
				} else {
					fb.removeControl('reservationRequired');
					fb.removeControl('reservationFee');
					if (this.$askingPriceReservationRequired?.unsubscribe)
						this.$askingPriceReservationRequired.unsubscribe();
				}
			});

		// fb.patchValue({
		// 	askingPrice: 110,
		// 	newBuild: false,
		// });
		return fb;
	}

	async saveAskingPrice() {
		await this.saveForm();
	}

	get leaseholdAgreementArray() {
		return this.tenureForm.get('leaseholdAgreement') as FormArray;
	}

	$tenure: any;
	$petRestrictions: any;
	$sinkingFund: any;
	$capitalWorksPlanned: any;
	$hasManagingAgent: any;
	buildTenureForm() {
		let fb = this.fb.group<{
			tenure: FormControl<string | null>;
			ownership: FormControl<string | null>;
			leaseholdAgreement?: FormArray<any>;
			originLengthOfLease?: FormControl<number | null>;
			leftOnLease?: FormControl<number | null>;
			groundRentCost?: FormControl<string | null>;
			serviceChargesCost?: FormControl<string | null>;
			periodOfServiceCharges?: FormControl<string | null>;
			sinkingFund?: FormControl<boolean | null>;
			percentToSinkingFund?: FormControl<number | null>;
			sinkingFundAmount?: FormControl<string | null>;
			capitalWorksPlanned?: FormControl<boolean | null>;
			capitalWorksDetails?: FormControl<string | null>;
			hasManagingAgent?: FormControl<boolean | null>;
			managingAgents?: FormArray<any>;
			petRestrictions?: FormControl<string | null>;
			petRestrictionsDetails?: FormControl<string | null>;
		}>({
			tenure: this.fb.control(null, [Validators.required]),
			ownership: this.fb.control(null, [Validators.required]),
		});

		if (this.$tenure?.unsubscribe) this.$tenure.unsubscribe();
		this.$tenure = fb.get('tenure')?.valueChanges.subscribe((tenure) => {
			if (tenure === 'Leasehold') {
				fb.addControl('leaseholdAgreement', this.fb.array([], []));
				fb.addControl(
					'originLengthOfLease',
					this.fb.control(null, [Validators.required])
				);
				fb.addControl(
					'leftOnLease',
					this.fb.control(null, [Validators.required])
				);
				fb.addControl(
					'groundRentCost',
					this.fb.control(null, [Validators.required])
				);
				fb.addControl(
					'serviceChargesCost',
					this.fb.control(null, [Validators.required])
				);
				fb.addControl(
					'periodOfServiceCharges',
					this.fb.control(null, [Validators.required])
				);
				fb.addControl(
					'sinkingFund',
					this.fb.control(null, [Validators.required])
				);
				fb.addControl(
					'hasManagingAgent',
					this.fb.control(null, [Validators.required])
				);
				fb.addControl(
					'capitalWorksPlanned',
					this.fb.control(null, [Validators.required])
				);

				fb.addControl(
					'petRestrictions',
					this.fb.control(null, [Validators.required])
				);

				if (this.$sinkingFund?.unsubscribe) this.$sinkingFund.unsubscribe();
				this.$sinkingFund = fb
					.get('sinkingFund')
					?.valueChanges.subscribe((sinkingFund) => {
						if (sinkingFund) {
							fb.addControl(
								'percentToSinkingFund',
								this.fb.control(null, [Validators.required])
							);
							fb.addControl(
								'sinkingFundAmount',
								this.fb.control(null, [Validators.required])
							);
						} else {
							fb.removeControl('percentToSinkingFund');
							fb.removeControl('sinkingFundAmount');
						}
					});

				if (this.$capitalWorksPlanned?.unsubscribe)
					this.$capitalWorksPlanned.unsubscribe();
				this.$capitalWorksPlanned = fb
					.get('capitalWorksPlanned')
					?.valueChanges.subscribe((capitalWorksPlanned) => {
						if (capitalWorksPlanned) {
							fb.addControl(
								'capitalWorksDetails',
								this.fb.control(null, [Validators.required])
							);
						} else {
							fb.removeControl('capitalWorksDetails');
						}
					});

				if (this.$hasManagingAgent?.unsubscribe)
					this.$hasManagingAgent.unsubscribe();
				this.$hasManagingAgent = fb
					.get('hasManagingAgent')
					?.valueChanges.subscribe((hasManagingAgent) => {
						if (hasManagingAgent) {
							fb.addControl(
								'managingAgents',
								this.fb.array([this.buildPerson()], [arrayLengthValidator(1)])
							);
						} else {
							fb.removeControl('managingAgents');
						}
					});

				if (this.$petRestrictions?.unsubscribe)
					this.$petRestrictions.unsubscribe();
				this.$petRestrictions = fb
					.get('petRestrictions')
					?.valueChanges.subscribe((petRestrictions) => {
						if (petRestrictions === 'restricted') {
							fb.addControl(
								'petRestrictionsDetails',
								this.fb.control(null, [Validators.required])
							);
						} else {
							fb.removeControl('petRestrictionsDetails');
						}
					});
			} else {
				fb.removeControl('originLengthOfLease');
				fb.removeControl('leftOnLease');
				fb.removeControl('groundRentCost');
				fb.removeControl('serviceChargesCost');
				fb.removeControl('periodOfServiceCharges');
				fb.removeControl('sinkingFund');
				fb.removeControl('percentToSinkingFund');
				fb.removeControl('sinkingFundAmount');
				fb.removeControl('capitalWorksPlanned');
				fb.removeControl('capitalWorksDetails');
				fb.removeControl('hasManagingAgent');
				fb.removeControl('managingAgents');
				fb.removeControl('petRestrictions');
				fb.removeControl('petRestrictionsDetails');

				if (this.$sinkingFund?.unsubscribe) this.$sinkingFund.unsubscribe();
				if (this.$capitalWorksPlanned?.unsubscribe)
					this.$capitalWorksPlanned.unsubscribe();
				if (this.$hasManagingAgent?.unsubscribe)
					this.$hasManagingAgent.unsubscribe();
				if (this.$petRestrictions?.unsubscribe)
					this.$petRestrictions.unsubscribe();
			}
		});

		//Solo Leasehold
		if (!this.property?.titles || this.property?.titles.length == 0)
			fb.patchValue({
				tenure: 'Leasehold',
				ownership: 'Sole',
			});
		else if (!!this.property?.titles?.find((t) => t.tenure == 'Leasehold')) {
			let ownerShipType =
				this.property?.titles?.find((t) => t.tenure == 'Leasehold')
					?.ownerShipType || 0;

			fb.patchValue({
				tenure: 'Leasehold',
				ownership: ownerShipType == 1 ? 'Joint' : 'Sole',
			});
		} else {
			let ownerShipType = this.property?.titles[0]?.ownerShipType || 0;
			fb.patchValue({
				tenure: 'Freehold',
				ownership: ownerShipType == 1 ? 'Joint' : 'Sole',
			});
		}

		return fb;
	}

	_addNewLeaseholdAgreement(data: any) {
		let fa = this.leaseholdAgreementArray;
		fa.push(
			this.fb.group<{
				id: FormControl<string | null>;
				name: FormControl<string | null>;
				date: FormControl<Date | null>;
			}>({
				id: this.fb.control(null, [Validators.required]),
				name: this.fb.control(null, [Validators.required]),
				date: this.fb.control(null, [Validators.required]),
			})
		);
	}

	async saveTenure() {
		await this.saveForm();
	}

	groundRentCostPerMonth() {
		const groundRentCost = parseFloat(
			(this.tenureForm.get('groundRentCost')?.value || '0')
				.toString()
				.replace(/,/g, '')
		);
		return groundRentCost / 12;
	}

	serviceChargesCostPerMonth() {
		const serviceChargesCost = parseFloat(
			(this.tenureForm.get('serviceChargesCost')?.value || '0')
				.toString()
				.replace(/,/g, '')
		);
		return serviceChargesCost / 12;
	}

	$currentLeasingTenant: any;
	$leaseBeyondSale: any;
	buildLeasingTenantForm() {
		let fb = this.fb.group<{
			currentLeasingTenant: FormControl<boolean | null>;
			leaseBeyondSale?: FormControl<boolean | null>;
			leaseEndDate?: FormControl<Date | null>;
		}>({
			currentLeasingTenant: this.fb.control(null, [Validators.required]),
		});

		if (this.$currentLeasingTenant?.unsubscribe)
			this.$currentLeasingTenant.unsubscribe();
		this.$currentLeasingTenant = fb
			.get('currentLeasingTenant')
			?.valueChanges.subscribe((currentLeasingTenant) => {
				if (currentLeasingTenant) {
					fb.addControl(
						'leaseBeyondSale',
						this.fb.control(null, [Validators.required])
					);
					if (this.$leaseBeyondSale?.unsubscribe)
						this.$leaseBeyondSale.unsubscribe();
					this.$leaseBeyondSale = fb
						.get('leaseBeyondSale')
						?.valueChanges.subscribe((leaseBeyondSale) => {
							if (leaseBeyondSale) {
								fb.addControl(
									'leaseEndDate',
									this.fb.control(null, [Validators.required])
								);
							} else {
								fb.removeControl('leaseEndDate');
							}
						});
				} else {
					fb.removeControl('leaseBeyondSale');
					if (this.$leaseBeyondSale?.unsubscribe)
						this.$leaseBeyondSale.unsubscribe();
				}
			});

		return fb;
	}

	async saveLeasingTenant() {
		await this.saveForm();
	}

	$propertyType: any;
	$wallConstruction: any;
	$roofType: any;
	$irregularBoundary: any;
	$boundaryMoved: any;
	buildPropertyConstructionForm() {
		let fb = this.fb.group<{
			originalConstructed: FormControl<string | null>;
			propertyType: FormControl<string | null>;
			otherPropertyType?: FormControl<string | null>;
			wallConstruction: FormControl<string[] | null>;
			otherWallConstruction?: FormControl<string | null>;
			roofType: FormControl<string[] | null>;
			roofMaterial: FormControl<string[] | null>;
			otherRoofMaterial?: FormControl<string | null>;
			leftBoundary?: FormControl<string | null>;
			rightBoundary?: FormControl<string | null>;
			rearBoundary?: FormControl<string | null>;
			frontBoundary?: FormControl<string | null>;
			irregularBoundary?: FormControl<boolean | null>;
			irregularBoundaryDetails?: FormControl<string | null>;
			boundaryMoved?: FormControl<boolean | null>;
			boundaryMovedDetails?: FormControl<string | null>;
		}>({
			originalConstructed: this.fb.control(null, [Validators.required]),
			propertyType: this.fb.control(null, [Validators.required]),
			wallConstruction: this.fb.control(null, [Validators.required]),
			roofType: this.fb.control(null, [Validators.required]),
			roofMaterial: this.fb.control(null, [Validators.required]),
		});

		if (this.$propertyType?.unsubscribe) this.$propertyType.unsubscribe();
		this.$propertyType = fb
			.get('propertyType')
			?.valueChanges.subscribe((propertyType) => {
				if (propertyType === 'Other') {
					fb.addControl(
						'otherPropertyType',
						this.fb.control(null, [Validators.required])
					);
				} else {
					fb.removeControl('otherPropertyType');
				}
				if (propertyType != 'Flat' && propertyType != 'Studio') {
					fb.addControl(
						'leftBoundary',
						this.fb.control(null, [Validators.required])
					);
					fb.addControl(
						'rightBoundary',
						this.fb.control(null, [Validators.required])
					);
					fb.addControl(
						'rearBoundary',
						this.fb.control(null, [Validators.required])
					);
					fb.addControl(
						'frontBoundary',
						this.fb.control(null, [Validators.required])
					);
					fb.addControl(
						'irregularBoundary',
						this.fb.control(null, [Validators.required])
					);
					if (this.$irregularBoundary?.unsubscribe)
						this.$irregularBoundary.unsubscribe();
					this.$irregularBoundary = fb
						.get('irregularBoundary')
						?.valueChanges.subscribe((irregularBoundary) => {
							if (irregularBoundary) {
								fb.addControl(
									'irregularBoundaryDetails',
									this.fb.control(null, [Validators.required])
								);
							} else {
								fb.removeControl('irregularBoundaryDetails');
							}
						});

					fb.addControl(
						'boundaryMoved',
						this.fb.control(null, [Validators.required])
					);

					if (this.$boundaryMoved?.unsubscribe)
						this.$boundaryMoved.unsubscribe();
					this.$boundaryMoved = fb
						.get('boundaryMoved')
						?.valueChanges.subscribe((boundaryMoved) => {
							if (boundaryMoved) {
								fb.addControl(
									'boundaryMovedDetails',
									this.fb.control(null, [Validators.required])
								);
							} else {
								fb.removeControl('boundaryMovedDetails');
							}
						});
				} else {
					fb.removeControl('leftBoundary');
					fb.removeControl('rightBoundary');
					fb.removeControl('rearBoundary');
					fb.removeControl('frontBoundary');
					fb.removeControl('irregularBoundary');
					fb.removeControl('irregularBoundaryDetails');
					fb.removeControl('boundaryMoved');
					fb.removeControl('boundaryMovedDetails');
					if (this.$irregularBoundary?.unsubscribe)
						this.$irregularBoundary.unsubscribe();
					if (this.$boundaryMoved?.unsubscribe)
						this.$boundaryMoved.unsubscribe();
				}
			});

		if (this.$wallConstruction?.unsubscribe)
			this.$wallConstruction.unsubscribe();
		this.$wallConstruction = fb
			.get('wallConstruction')
			?.valueChanges.subscribe((wallConstruction) => {
				if (wallConstruction?.includes('other')) {
					fb.addControl(
						'otherWallConstruction',
						this.fb.control(null, [Validators.required])
					);
				} else {
					fb.removeControl('otherWallConstruction');
				}
			});

		if (this.$roofType?.unsubscribe) this.$roofType.unsubscribe();
		this.$roofType = fb
			.get('roofMaterial')
			?.valueChanges.subscribe((roofType) => {
				if (roofType?.includes('other')) {
					fb.addControl(
						'otherRoofMaterial',
						this.fb.control(null, [Validators.required])
					);
				} else {
					fb.removeControl('otherRoofMaterial');
				}
			});

		// fb.patchValue({
		// 	originalConstructed: '2020s',
		// 	propertyType: 'flat',
		// 	wallConstruction: ['brick', 'stone'],
		// 	roofType: ['flat'],
		// 	roofMaterial: ['slate'],
		// });

		return fb;
	}

	async savePropertyConstruction() {
		await this.saveForm();
	}

	get spacesArray() {
		return this.spacesForm.get('spaces') as FormArray<
			FormGroup<{
				indoorOutdoor: FormControl<string | null>;
				type: FormControl<string | null>;
				width: FormControl<number | null>;
				length: FormControl<number | null>;
				ceilingHeight: FormControl<number | null>;
				ceilingType: FormControl<string | null>;
				shape: FormControl<string | null>;
				shower: FormControl<boolean | null>;
				bath: FormControl<boolean | null>;
				toilet: FormControl<boolean | null>;
				name: FormControl<string | null>;
			}>
		>;
	}

	buildSpacesForm() {
		let fb = this.fb.group<{
			spaces: FormArray<
				FormGroup<{
					indoorOutdoor: FormControl<string | null>;
					type: FormControl<string | null>;
					width: FormControl<number | null>;
					length: FormControl<number | null>;
					ceilingHeight: FormControl<number | null>;
					ceilingType: FormControl<string | null>;
					shape: FormControl<string | null>;
					shower: FormControl<boolean | null>;
					bath: FormControl<boolean | null>;
					toilet: FormControl<boolean | null>;
					name: FormControl<string | null>;
				}>
			>;
			floorSpace: FormControl<number | null>;
			frontDoorLevel: FormControl<number | null>;
			numberOfFloors: FormControl<number | null>;
		}>({
			spaces: this.fb.array<
				FormGroup<{
					indoorOutdoor: FormControl<string | null>;
					type: FormControl<string | null>;
					width: FormControl<number | null>;
					length: FormControl<number | null>;
					ceilingHeight: FormControl<number | null>;
					ceilingType: FormControl<string | null>;
					shape: FormControl<string | null>;
					shower: FormControl<boolean | null>;
					bath: FormControl<boolean | null>;
					toilet: FormControl<boolean | null>;
					name: FormControl<string | null>;
				}>
			>([], [arrayLengthValidator(1)]),
			floorSpace: this.fb.control(null, [Validators.required]),
			frontDoorLevel: this.fb.control(null, [Validators.required]),
			numberOfFloors: this.fb.control(null, [Validators.required]),
		});

		// fb.patchValue({
		// 	floorSpace: 110,
		// 	frontDoorLevel: 1,
		// 	numberOfFloors: 2,
		// });

		// (fb.get('spaces') as FormArray).controls.push(
		// 	this.fb.group({
		// 		indoorOutdoor: this.fb.control('outdoor', [Validators.required]),
		// 		type: this.fb.control('Garden', [Validators.required]),
		// 		width: this.fb.control(3, [Validators.required]),
		// 		length: this.fb.control(4, [Validators.required]),
		// 		shape: this.fb.control('rectangular', [Validators.required]),
		// 		name: this.fb.control(null, []),
		// 	})
		// );

		return fb;
	}

	async saveSpaces() {
		await this.saveForm();
	}

	async addSpace() {
		let ref = this.dialog.open(EditSpaceComponent, {
			data: {
				actions: 'add',
				spaceData: {},
			},
		});
		let r = await lastValueFrom(ref.afterClosed());
		if (!r) return;

		this._addNewSpace(r);
	}

	_addNewSpace(data: spaceData) {
		let spaces = this.spacesForm.get('spaces') as FormArray;
		let newSpace = this.fb.group({
			indoorOutdoor: this.fb.control(null, [Validators.required]),
			type: this.fb.control(null, [Validators.required]),
			width: this.fb.control(null, [Validators.required]),
			length: this.fb.control(null, [Validators.required]),
			ceilingHeight: this.fb.control(null, []),
			ceilingType: this.fb.control(null, []),
			shape: this.fb.control(null, [Validators.required]),
			shower: this.fb.control(null, []),
			bath: this.fb.control(null, []),
			toilet: this.fb.control(null, []),
			name: this.fb.control(null, []),
		});
		//console.log('return', r);
		newSpace.setValue({
			...{
				indoorOutdoor: null,
				type: null,
				width: null,
				length: null,
				ceilingHeight: null,
				ceilingType: null,
				shape: null,
				shower: null,
				bath: null,
				toilet: null,
				name: null,
			},
			...(data as any),
		});
		spaces.push(newSpace);
	}

	async removeSpace(index: number) {
		let r = await this.confirmDialog.confirm({
			title: 'Remove Space',
			message: 'Are you sure you want to remove this space?',
			confirmText: 'Yes',
			cancelText: 'No',
		});
		if (!r) return;
		let spaces = this.spacesForm.get('spaces') as FormArray;
		spaces.removeAt(index);
	}

	async editSpace(index: number) {
		let ref = this.dialog.open(EditSpaceComponent, {
			data: {
				actions: 'edit',
				spaceData: this.spacesArray.at(index).value,
			},
		});
		let r = await lastValueFrom(ref.afterClosed());
		if (!r) return;

		let editSpaceForm = this.spacesArray.at(index);

		editSpaceForm.setValue({
			...{
				indoorOutdoor: null,
				type: null,
				width: null,
				length: null,
				ceilingHeight: null,
				ceilingType: null,
				shape: null,
				shower: null,
				bath: null,
				toilet: null,
				name: null,
			},
			...r,
		});
	}

	spaceTypes(spaceLocation: string | null | undefined) {
		if (spaceLocation === 'indoor') {
			return [
				'Living Room',
				'Kitchen',
				'Bedroom',
				'Bathroom',
				'Hallway',
				'Open Plan Area',
				'Office',
				'Attic',
				'Basement',
				'Closet',
				'Other',
			];
		} else {
			return [
				'Garden',
				'Balcony',
				'Patio',
				'Terrace',
				'Roof Terrace',
				'Garage',
				'Shed',
				'Other',
			];
		}
	}

	get worksArray() {
		return this.knownWorksForm.get('knownWorks') as FormArray<
			FormGroup<{
				description: FormControl<string | null>;
				completionDate: FormControl<Date | null>;
			}>
		>;
	}

	$knownWorks: any;
	buildKnownWorksForm() {
		let fb = this.fb.group<{
			knowledgedOfWorks: FormControl<boolean | null>;
			knownWorks?: FormArray<
				FormGroup<{
					description: FormControl<string | null>;
					completionDate: FormControl<Date | null>;
				}>
			>;
		}>({
			knowledgedOfWorks: this.fb.control(null, [Validators.required]),
			// knownWorks: this.fb.array<
			// 	FormGroup<{
			// 		description: FormControl<string | null>;
			// 		completionDate: FormControl<Date | null>;
			// 	}>
			// >([], []),
		});

		if (this.$knownWorks?.unsubscribe) this.$knownWorks.unsubscribe();
		this.$knownWorks = fb
			.get('knowledgedOfWorks')
			?.valueChanges.subscribe((knowledgedOfWorks) => {
				if (knowledgedOfWorks) {
					fb.addControl(
						'knownWorks',
						this.fb.array<
							FormGroup<{
								description: FormControl<string | null>;
								completionDate: FormControl<Date | null>;
							}>
						>([], [arrayLengthValidator(1)])
					);
				} else {
					fb.removeControl('knownWorks');
				}
			});

		return fb;
	}

	async saveKnownWorks() {
		await this.saveForm();
	}

	async addWork() {
		let ref = this.dialog.open(EditWorksComponent, {
			data: {
				actions: 'add',
				spaceData: {},
			},
		});
		let r = await lastValueFrom(ref.afterClosed());

		if (!r) return;

		this._addNewWork(r);
	}

	_addNewWork(data: workData) {
		let knownWorks = this.knownWorksForm.get('knownWorks') as FormArray;
		let newWork = this.fb.group({
			description: this.fb.control(null, [Validators.required]),
			completionDate: this.fb.control(null, [Validators.required]),
		});

		newWork.setValue({
			...{
				description: null,
				completionDate: null,
			},
			...(data as any),
		});
		knownWorks.push(newWork);
	}

	async removeWork(index: number) {
		let r = await this.confirmDialog.confirm({
			title: 'Remove Work',
			message: 'Are you sure you want to remove this work?',
			confirmText: 'Yes',
			cancelText: 'No',
		});
		if (!r) return;
		let knownWorks = this.knownWorksForm.get('knownWorks') as FormArray;
		knownWorks.removeAt(index);
	}

	async editWork(index: number) {
		let ref = this.dialog.open(EditWorksComponent, {
			data: {
				actions: 'edit',
				workData: this.worksArray.at(index).value,
			},
		});

		let r = await lastValueFrom(ref.afterClosed());

		if (!r) return;

		let editWorkForm = this.worksArray.at(index);

		editWorkForm.setValue({
			...{
				description: null,
				completionDate: null,
			},
			...r,
		});
	}

	get utilitiesHeatingSources() {
		return this.utilitiesForm.get('heatingSources') as FormArray<
			FormGroup<{
				type: FormControl<string | null>;
				cost?: FormControl<string | null>;
			}>
		>;
	}

	$sewerageType: any;
	buildUtilitiesForm() {
		let fb = this.fb.group<{
			electricityCost: FormControl<string | null>;
			electricityAltCost: FormControl<string | null>;
			electricityAltDetails: FormControl<string | null>;
			waterCost: FormControl<string | null>;
			waterAltCost: FormControl<string | null>;
			waterAltDetails: FormControl<string | null>;
			sewerageCost: FormControl<string | null>;
			sewerageType: FormControl<string | null>;
			sewerageOther?: FormControl<string | null>;
			heatingSources: FormArray<
				FormGroup<{
					type: FormControl<string | null>;
					cost?: FormControl<string | null>;
					otherDetails?: FormControl<string | null>;
				}>
			>;
		}>({
			electricityCost: this.fb.control(null, [Validators.required]),
			electricityAltCost: this.fb.control('0', []),
			electricityAltDetails: this.fb.control(null, []),
			waterCost: this.fb.control(null, [Validators.required]),
			waterAltCost: this.fb.control('0', []),
			waterAltDetails: this.fb.control(null, []),
			sewerageCost: this.fb.control('0', [Validators.required]),
			sewerageType: this.fb.control(null, [Validators.required]),
			heatingSources: this.fb.array<
				FormGroup<{
					type: FormControl<string | null>;
					cost?: FormControl<string | null>;
					otherDetails?: FormControl<string | null>;
				}>
			>([], []),
		});

		if (this.$sewerageType?.unsubscribe) this.$sewerageType.unsubscribe();
		this.$sewerageType = fb
			.get('sewerageType')
			?.valueChanges.subscribe((sewerageType) => {
				if (sewerageType === 'other') {
					fb.addControl(
						'sewerageOther',
						this.fb.control(null, [Validators.required])
					);
				} else {
					fb.removeControl('sewerageOther');
				}
			});

		return fb;
	}

	async saveUtilities() {
		await this.saveForm();
	}

	_addNewHeatingSource(data: heatData) {
		let heatingSources = this.utilitiesForm.get('heatingSources') as FormArray;

		let newHeatingSourceGroup = this.fb.group<{
			type: FormControl<string | null>;
			cost?: FormControl<string | null>;
			otherDetails?: FormControl<string | null>;
		}>({
			type: this.fb.control(data.type || null, [Validators.required]),
			otherDetails: this.fb.control(null, []),
			cost: this.fb.control(null, []),
		});

		newHeatingSourceGroup.setValue({
			...{
				type: null,
				cost: null,
				otherDetails: null,
			},
			...data,
		});

		heatingSources.push(newHeatingSourceGroup);
	}

	async addHeatingSource() {
		let ref = this.dialog.open(EditHeatComponent, {
			data: {
				actions: 'add',
				heatData: {},
			},
		});

		let r = await lastValueFrom(ref.afterClosed());

		if (!r) return;

		this._addNewHeatingSource(r);
	}

	async editHeatingSource(index: number) {
		let ref = this.dialog.open(EditHeatComponent, {
			data: {
				actions: 'edit',
				heatData: this.utilitiesHeatingSources.at(index).value,
			},
		});

		let r = await lastValueFrom(ref.afterClosed());

		if (!r) return;

		let editHeatingSourceForm = this.utilitiesHeatingSources.at(index);

		editHeatingSourceForm.setValue({
			...{
				type: null,
				cost: null,
				otherDetails: null,
			},
			...r,
		});
	}

	async removeHeatingSource(index: number) {
		let r = await this.confirmDialog.confirm({
			title: 'Remove Heating Source',
			message: 'Are you sure you want to remove this heating source?',
			confirmText: 'Remove',
			cancelText: 'No',
		});
		if (!r) return;
		let heatingSources = this.utilitiesForm.get('heatingSources') as FormArray;
		heatingSources.removeAt(index);
	}

	get parkingSpots() {
		return this.parkingBikeStorageForm.get('parkingSpots') as FormArray<
			FormGroup<{
				type: FormControl<string | null>;
				cost: FormControl<string | null>;
				numberOfSpots?: FormControl<number | null>;
				notes: FormControl<string | null>;
			}>
		>;
	}

	get bikeStorageSpots() {
		return this.parkingBikeStorageForm.get('bikeStorage') as FormArray<
			FormGroup<{
				type: FormControl<string | null>;
				cost: FormControl<string | null>;
				numberOfSpots?: FormControl<number | null>;
				eBikes: FormControl<boolean | null>;
				cargoBikes: FormControl<boolean | null>;
				notes: FormControl<string | null>;
			}>
		>;
	}

	$parkingAvailable: any;
	$bikeStorageAvailable: any;
	buildParkingBikeStorageForm() {
		let fb = this.fb.group<{
			parkingAvailable: FormControl<boolean | null>;
			parkingSpots?: FormArray<
				FormGroup<{
					type: FormControl<string | null>;
					cost: FormControl<string | null>;
					numberOfSpots: FormControl<number | string | null>;
					notes: FormControl<string | null>;
				}>
			>;
			bikeStorageAvailable: FormControl<boolean | null>;
			bikeStorage?: FormArray<
				FormGroup<{
					type: FormControl<string | null>;
					cost: FormControl<string | null>;
					numberOfSpots: FormControl<number | string | null>;
					eBikes: FormControl<boolean | null>;
					cargoBikes: FormControl<boolean | null>;
					notes: FormControl<string | null>;
				}>
			>;
		}>({
			parkingAvailable: this.fb.control(null, [Validators.required]),
			bikeStorageAvailable: this.fb.control(null, [Validators.required]),
		});

		if (this.$parkingAvailable?.unsubscribe)
			this.$parkingAvailable.unsubscribe();
		this.$parkingAvailable = fb
			.get('parkingAvailable')
			?.valueChanges.subscribe((parkingAvailable) => {
				if (parkingAvailable) {
					fb.addControl(
						'parkingSpots',
						this.fb.array<
							FormGroup<{
								type: FormControl<string | null>;
								cost: FormControl<string | null>;
								numberOfSpots: FormControl<number | string | null>;
								notes: FormControl<string | null>;
							}>
						>([], [arrayLengthValidator(1)])
					);
				} else {
					fb.removeControl('parkingSpots');
				}
			});

		if (this.$bikeStorageAvailable?.unsubscribe)
			this.$bikeStorageAvailable.unsubscribe();
		this.$bikeStorageAvailable = fb
			.get('bikeStorageAvailable')
			?.valueChanges.subscribe((bikeStorageAvailable) => {
				if (bikeStorageAvailable) {
					fb.addControl(
						'bikeStorage',
						this.fb.array<
							FormGroup<{
								type: FormControl<string | null>;
								cost: FormControl<string | null>;
								numberOfSpots: FormControl<number | string | null>;
								eBikes: FormControl<boolean | null>;
								cargoBikes: FormControl<boolean | null>;
								notes: FormControl<string | null>;
							}>
						>([], [arrayLengthValidator(1)])
					);
				} else {
					fb.removeControl('bikeStorage');
				}
			});

		return fb;
	}

	async saveParkingBikeStorage() {
		await this.saveForm();
	}

	_addNewParkingSpot(data: parkingSpotData) {
		let parkingSpots = this.parkingBikeStorageForm.get(
			'parkingSpots'
		) as FormArray;

		let newParkingSpotGroup = this.fb.group<{
			type: FormControl<string | null>;
			cost: FormControl<string | null>;
			numberOfSpots: FormControl<number | string | null>;
			notes: FormControl<string | null>;
		}>({
			type: this.fb.control(null, [Validators.required]),
			cost: this.fb.control(null, [Validators.required]),
			numberOfSpots: this.fb.control(null, [Validators.required]),
			notes: this.fb.control(null, []),
		});

		newParkingSpotGroup.setValue({
			...{
				type: null,
				cost: null,
				numberOfSpots: null,
				notes: null,
			},
			...data,
		});

		parkingSpots.push(newParkingSpotGroup);
	}

	async addParkingSpot() {
		let ref = this.dialog.open(EditParkingSpotComponent, {
			data: {
				actions: 'add',
				parkingSpotData: {},
			},
		});

		let r = await lastValueFrom(ref.afterClosed());

		if (!r) return;

		this._addNewParkingSpot(r);
	}

	async editParkingSpot(index: number) {
		let ref = this.dialog.open(EditParkingSpotComponent, {
			data: {
				actions: 'edit',
				parkingSpotData: this.parkingSpots.at(index).value,
			},
		});

		let r = await lastValueFrom(ref.afterClosed());

		if (!r) return;

		let editParkingSpotForm = this.parkingSpots.at(index);

		editParkingSpotForm.setValue({
			...{
				type: null,
				cost: null,
				numberOfSpots: null,
				notes: null,
			},
			...r,
		});
	}

	async removeParkingSpot(index: number) {
		let r = await this.confirmDialog.confirm({
			title: 'Remove Parking Spot',
			message: 'Are you sure you want to remove this parking spot?',
			confirmText: 'Remove',
			cancelText: 'No',
		});
		if (!r) return;

		let parkingSpots = this.parkingBikeStorageForm.get(
			'parkingSpots'
		) as FormArray;
		parkingSpots.removeAt(index);
	}

	_addNewBikeStorageSpot(data: bikeStorageData) {
		let bikeStorageSpots = this.parkingBikeStorageForm.get(
			'bikeStorage'
		) as FormArray;

		let newBikeStorageSpotGroup = this.fb.group<{
			type: FormControl<string | null>;
			cost: FormControl<string | null>;
			numberOfSpots: FormControl<number | string | null>;
			eBikes: FormControl<boolean | null>;
			cargoBikes: FormControl<boolean | null>;
			notes: FormControl<string | null>;
		}>({
			type: this.fb.control(null, [Validators.required]),
			cost: this.fb.control(null, [Validators.required]),
			numberOfSpots: this.fb.control(null, [Validators.required]),
			eBikes: this.fb.control(null, []),
			cargoBikes: this.fb.control(null, []),
			notes: this.fb.control(null, []),
		});

		newBikeStorageSpotGroup.setValue({
			...{
				type: null,
				cost: null,
				numberOfSpots: null,
				eBikes: null,
				cargoBikes: null,
				notes: null,
			},
			...data,
		});

		bikeStorageSpots.push(newBikeStorageSpotGroup);
	}

	async addBikeStorageSpot() {
		let ref = this.dialog.open(EditBikeStorageComponent, {
			data: {
				actions: 'add',
				bikeStorageData: {},
			},
		});

		let r = await lastValueFrom(ref.afterClosed());

		if (!r) return;

		this._addNewBikeStorageSpot(r);
	}

	async editBikeStorageSpot(index: number) {
		let ref = this.dialog.open(EditBikeStorageComponent, {
			data: {
				actions: 'edit',
				bikeStorageData: this.bikeStorageSpots.at(index).value,
			},
		});

		let r = await lastValueFrom(ref.afterClosed());

		if (!r) return;

		let editBikeStorageSpotForm = this.bikeStorageSpots.at(index);

		editBikeStorageSpotForm.setValue({
			...{
				type: null,
				cost: null,
				numberOfSpots: null,
				eBikes: null,
				cargoBikes: null,
				notes: null,
			},
			...r,
		});
	}

	async removeBikeStorageSpot(index: number) {
		let r = await this.confirmDialog.confirm({
			title: 'Remove Bike Storage Spot',
			message: 'Are you sure you want to remove this bike storage spot?',
			confirmText: 'Remove',
			cancelText: 'No',
		});
		if (!r) return;

		let bikeStorageSpots = this.parkingBikeStorageForm.get(
			'bikeStorage'
		) as FormArray;
		bikeStorageSpots.removeAt(index);
	}

	get issuesArray() {
		return this.buildingSafetyIssuesForm.get('issues') as FormArray<
			FormGroup<{
				type: FormControl<string | null>;
				additionalDetails: FormControl<string | null>;
				hasBeenFixed: FormControl<string | null>;
				stillNeedsFixing: FormControl<string | null>;
				outstandingCost: FormControl<string | null>;
				canReside: FormControl<boolean | null>;
			}>
		>;
	}

	$anyKnownIssues: any;
	buildBuildingSafetyIssuesForm() {
		let fb = this.fb.group<{
			anyKnownIssues: FormControl<boolean | null>;
			issues?: FormArray<
				FormGroup<{
					type: FormControl<string | null>;
					additionalDetails: FormControl<string | null>;
					hasBeenFixed: FormControl<string | null>;
					stillNeedsFixing: FormControl<string | null>;
					outstandingCost: FormControl<string | null>;
					canReside: FormControl<boolean | null>;
				}>
			>;
		}>({
			anyKnownIssues: this.fb.control(null, [Validators.required]),
		});

		if (this.$anyKnownIssues?.unsubscribe) this.$anyKnownIssues.unsubscribe();
		this.$anyKnownIssues = fb
			.get('anyKnownIssues')
			?.valueChanges.subscribe((anyKnownIssues) => {
				if (anyKnownIssues) {
					fb.addControl(
						'issues',
						this.fb.array<
							FormGroup<{
								type: FormControl<string | null>;
								additionalDetails: FormControl<string | null>;
								hasBeenFixed: FormControl<string | null>;
								stillNeedsFixing: FormControl<string | null>;
								outstandingCost: FormControl<string | null>;
								canReside: FormControl<boolean | null>;
							}>
						>([], [arrayLengthValidator(1)])
					);
				} else {
					fb.removeControl('issues');
				}
			});

		return fb;
	}

	async saveBuildingSafetyIssues() {
		await this.saveForm();
	}

	_addNewIssue(data: buildingIssueData) {
		let issues = this.buildingSafetyIssuesForm.get('issues') as FormArray;

		let newIssueGroup = this.fb.group<{
			type: FormControl<string | null>;
			additionalDetails: FormControl<string | null>;
			hasBeenFixed: FormControl<string | null>;
			stillNeedsFixing: FormControl<string | null>;
			outstandingCost: FormControl<string | null>;
			canReside: FormControl<boolean | null>;
		}>({
			type: this.fb.control(null, [Validators.required]),
			additionalDetails: this.fb.control(null, []),
			hasBeenFixed: this.fb.control(null, []),
			stillNeedsFixing: this.fb.control(null, []),
			outstandingCost: this.fb.control(null, []),
			canReside: this.fb.control(null, []),
		});

		newIssueGroup.setValue({
			...{
				type: null,
				additionalDetails: null,
				hasBeenFixed: null,
				stillNeedsFixing: null,
				outstandingCost: null,
				canReside: null,
			},
			...data,
		} as any);

		issues.push(newIssueGroup);
	}

	async addIssue() {
		let ref = this.dialog.open(EditBuildingIssueComponent, {
			data: {
				actions: 'add',
				buildingIssueData: {},
			},
		});

		let r = await lastValueFrom(ref.afterClosed());

		if (!r) return;

		this._addNewIssue(r);
	}

	async editIssue(index: number) {
		let ref = this.dialog.open(EditBuildingIssueComponent, {
			data: {
				actions: 'edit',
				buildingIssueData: this.issuesArray.at(index).value,
			},
		});

		let r = await lastValueFrom(ref.afterClosed());

		if (!r) return;

		let editIssueForm = this.issuesArray.at(index);

		editIssueForm.setValue({
			...{
				type: null,
				additionalDetails: null,
				hasBeenFixed: null,
				stillNeedsFixing: null,
				outstandingCost: null,
				canReside: null,
			},
			...r,
		});
	}

	async removeIssue(index: number) {
		let r = await this.confirmDialog.confirm({
			title: 'Remove Issue',
			message: 'Are you sure you want to remove this issue?',
			confirmText: 'Remove',
			cancelText: 'No',
		});
		if (!r) return;
		let issues = this.buildingSafetyIssuesForm.get('issues') as FormArray;
		issues.removeAt(index);
	}

	$rightsAndEasements: any;
	buildRightsAndEasementsForm() {
		let fb = this.fb.group<{
			rightsAndEasements: FormControl<boolean | null>;
			rightsAndEasementsDetails?: FormControl<string | null>;
		}>({
			rightsAndEasements: this.fb.control(null, [Validators.required]),
		});

		if (this.$rightsAndEasements?.unsubscribe)
			this.$rightsAndEasements.unsubscribe();
		this.$rightsAndEasements = fb
			.get('rightsAndEasements')
			?.valueChanges.subscribe((value) => {
				if (value) {
					fb.addControl(
						'rightsAndEasementsDetails',
						this.fb.control(null, [Validators.required])
					);
				} else {
					fb.removeControl('rightsAndEasementsDetails');
				}
			});

		return fb;
	}

	async saveRightsAndEasements() {
		await this.saveForm();
	}

	buildAccessibilityForm() {
		let fb = this.fb.group<{
			stepFreeAccess: FormControl<boolean | null>;
			wetRoom: FormControl<boolean | null>;
			lateralLiving: FormControl<boolean | null>;
			details: FormControl<string | null>;
		}>({
			stepFreeAccess: this.fb.control(null, [Validators.required]),
			wetRoom: this.fb.control(null, [Validators.required]),
			lateralLiving: this.fb.control(null, [Validators.required]),
			details: this.fb.control(null, []),
		});

		return fb;
	}

	async saveAccessibility() {
		await this.saveForm();
	}

	buildConfirmationForm() {
		let fb = this.fb.group<{
			confirmAllIsAccurate: FormControl<boolean | null>;
			confirmUnderstandPublic: FormControl<boolean | null>;
			signature: FormControl<string | null>;
			fullName: FormControl<string | null>;
		}>({
			confirmAllIsAccurate: this.fb.control(null, [Validators.requiredTrue]),
			confirmUnderstandPublic: this.fb.control(null, [Validators.requiredTrue]),
			fullName: this.fb.control(null, [Validators.required]),
			signature: this.fb.control(null, [Validators.required]),
		});

		return fb;
	}

	async Submit() {
		let r = await this.materialBackend.post(
			this.propertyId || '',
			this.currentSavedForm?.id || '',
			this.form?.value as any
		);
		this.form.markAsPristine();
		this.form.markAsUntouched();

		this.router.navigate(['/material-information', this.propertyId], {
			queryParams: { hideSide: true },
		});
	}

	test() {
		let r = this.spacesForm.value;
		console.log(r);
		console.log(JSON.stringify(this.spacesForm.value));
		return r;
	}

	buildPerson() {
		return this.fb.group({
			firstName: [environment.firstName, [Validators.required]],
			lastName: [environment.lastName, [Validators.required]],
			email: [
				environment.email,
				[Validators.required, Validators.email],
				//[this.peopleBackend.validEmail()],
			],
			phoneCountryCode: ['+44', [Validators.required]],
			phoneInCountryNumber: [
				'',
				[Validators.required, Validators.pattern('^[1-9]\\d{9}')],
			],
			company: [null],
		});
	}
}

export function arrayLengthValidator(minSize: number): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const _c = control as FormArray;
		const size = _c.length;

		return size < minSize ? { arrayMinSize: true } : null;
	};
}
