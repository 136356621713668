import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	AbstractControl,
	FormControl,
	FormArray,
	Validators,
	ValidatorFn,
	ValidationErrors,
} from '@angular/forms';
import {
	atLeastOneRequired,
	requiredIfOtherFieldHasValue,
} from 'src/app/form-fields/form-validators';
import { ConfirmExitFrom } from 'src/app/services/forms.service';
import { environment } from 'src/environments/environment';
import { userType } from '../httpTypes';
import { PeopleBackendService } from 'src/app/services/people-backend.service';

type booleanString = 'Y' | 'N' | '' | null | undefined;

@Component({
	selector: 'app-buyer-seller-setup',
	templateUrl: './buyer-seller-setup.component.html',
	styleUrls: ['./buyer-seller-setup.component.css'],
})
export class BuyerSellerSetupComponent implements OnInit, OnDestroy {
	@Output() data = new EventEmitter();
	@Output() submit = new EventEmitter();
	@Output() back = new EventEmitter();

	// Form Groups
	//--------------------------------------------

	@ConfirmExitFrom() purchaseInfoForm = this.fb.group<{
		buyingPropertyYN: FormControl<booleanString>;
		acceptedOfferYN: FormControl<booleanString>;
		address?: FormGroup<any>;
		sellingAgent?: FormArray<any>;
	}>(
		{
			buyingPropertyYN: this.fb.control('', [Validators.required]),
			acceptedOfferYN: this.fb.control('', [
				requiredIfOtherFieldHasValue('buyingPropertyYN', 'Y'),
			]),
		},
		{ validators: [this.buyingOrSellingValidator()] }
	);

	get buyingPropertyYN() {
		return this.purchaseInfoForm?.get('buyingPropertyYN');
	}

	get acceptedOfferYN() {
		return this.purchaseInfoForm?.get('acceptedOfferYN');
	}

	buyingPropertyYN$: any;
	acceptedOfferYN$: any;
	purchaseInfoForm$: any;

	//--------------------------------------------
	@ConfirmExitFrom() mortgageInfoForm = this.fb.group<{
		mortgageNeededYN: FormControl<booleanString>;
		haveMortgageBrokerYN: FormControl<booleanString>;
		mortgageBroker?: FormArray<any>;
	}>({
		mortgageNeededYN: this.fb.control('', [Validators.required]),
		haveMortgageBrokerYN: this.fb.control('', [
			requiredIfOtherFieldHasValue('mortgageNeededYN', 'Y'),
		]),
	});

	get mortgageNeededYN() {
		return this.mortgageInfoForm?.get('mortgageNeededYN');
	}

	get haveMortgageBrokerYN() {
		return this.mortgageInfoForm?.get('haveMortgageBrokerYN');
	}

	haveMortgageBrokerYN$: any;
	mortgageInfoForm$: any;

	//--------------------------------------------
	@ConfirmExitFrom() sellingInfoForm = this.fb.group<{
		sellingPropertyYN: FormControl<string | null>;
		acceptedOfferYN: FormControl<string | null>;
		dateOfferAccepted: FormControl<string | null>;
		agreedSellingPrice: FormControl<number | null>;
		address?: FormGroup<any>;
		sellingAgent?: FormArray<any>;
	}>(
		{
			sellingPropertyYN: this.fb.control('', [Validators.required]),
			acceptedOfferYN: this.fb.control('', [
				requiredIfOtherFieldHasValue('sellingPropertyYN', 'Y'),
			]),
			dateOfferAccepted: this.fb.control('', [
				requiredIfOtherFieldHasValue('acceptedOfferYN', 'Y'),
			]),
			agreedSellingPrice: this.fb.control(null, [
				requiredIfOtherFieldHasValue('acceptedOfferYN', 'Y'),
			]),
		},
		{ validators: [this.buyingOrSellingValidator()] }
	);

	get sellingPropertyYN() {
		return this.sellingInfoForm?.get('sellingPropertyYN');
	}

	get sellingAcceptedOfferYN() {
		return this.sellingInfoForm?.get('acceptedOfferYN');
	}

	sellingPropertyYN$: any;
	agreedSellingPrice$: any;
	sellingInfoForm$: any;

	//--------------------------------------------
	@ConfirmExitFrom() conveyancerInfoForm = this.fb.group<{
		conveyancerHasOneYN: FormControl<string | null>;
		conveyancer?: FormArray<any>;
	}>({
		conveyancerHasOneYN: this.fb.control('', [Validators.required]),
	});

	get conveyancerHasOneYN() {
		return this.conveyancerInfoForm?.get('conveyancerHasOneYN');
	}

	conveyancerHasOneYN$: any;
	conveyancerInfoForm$: any;

	//--------------------------------------------

	constructor(
		public fb: FormBuilder,
		public cd: ChangeDetectorRef,
		public peopleBackend: PeopleBackendService
	) {}

	ngOnInit(): void {
		this.buyingPropertyYN$ = this.buyingPropertyYN?.valueChanges.subscribe(
			(value) => {
				if (value == 'N') {
					this.acceptedOfferYN?.setValue(null);
				}
				this.sellingInfoForm.updateValueAndValidity();
				this.cd.detectChanges();
			}
		);

		this.acceptedOfferYN$ = this.acceptedOfferYN?.valueChanges.subscribe(
			(value) => {
				if (value == 'Y') {
					if (!this.purchaseInfoForm.get('sellingAgent'))
						this.purchaseInfoForm.addControl(
							'sellingAgent',
							this.buildPersonFormArray()
						);
					if (!this.purchaseInfoForm.get('address'))
						this.purchaseInfoForm.addControl(
							'address',
							this.buildAddressForm()
						);
				} else if (value == 'N' || value == null) {
					if (this.purchaseInfoForm.get('sellingAgent'))
						this.purchaseInfoForm.removeControl('sellingAgent');
					if (this.purchaseInfoForm.get('address'))
						this.purchaseInfoForm.removeControl('address');
				}

				this.cd.detectChanges();
			}
		);

		this.purchaseInfoForm$ = this.purchaseInfoForm?.valueChanges.subscribe(
			(value) => {
				try {
					localStorage.setItem('oldPurchaseInfoForm', JSON.stringify(value));
				} catch (error) {}
				this.submitBuild();
			}
		);

		this.haveMortgageBrokerYN$ =
			this.haveMortgageBrokerYN?.valueChanges.subscribe((value) => {
				if (value == 'Y' && !this.mortgageInfoForm.get('mortgageBroker'))
					this.mortgageInfoForm.addControl(
						'mortgageBroker',
						this.buildPersonFormArray()
					);
				else if (value == 'N' && this.mortgageInfoForm.get('mortgageBroker'))
					this.mortgageInfoForm.removeControl('mortgageBroker');

				this.cd.detectChanges();
			});

		this.mortgageInfoForm$ = this.mortgageInfoForm?.valueChanges.subscribe(
			(value) => {
				try {
					localStorage.setItem('oldMortgageInfoForm', JSON.stringify(value));
				} catch (error) {}
				this.submitBuild();
			}
		);

		this.sellingPropertyYN$ = this.sellingPropertyYN?.valueChanges.subscribe(
			(value) => {
				if (value == 'Y') {
					if (!this.sellingInfoForm.get('sellingAgent'))
						this.sellingInfoForm.addControl(
							'sellingAgent',
							this.buildPersonFormArray()
						);
					if (!this.sellingInfoForm.get('address'))
						this.sellingInfoForm.addControl('address', this.buildAddressForm());
				} else if (value == 'N' || value == null) {
					if (this.sellingInfoForm.get('sellingAgent'))
						this.sellingInfoForm.removeControl('sellingAgent');
					if (this.sellingInfoForm.get('address'))
						this.sellingInfoForm.removeControl('address');
				}

				this.cd.detectChanges();
			}
		);

		// this.agreedSellingPrice$ = this.sellingInfoForm
		// 	.get('agreedSellingPrice')
		// 	?.valueChanges.subscribe((value) => {
		// 		if (value) {
		// 			let _value = value + '';
		// 			let formattedValue = new Intl.NumberFormat('en-GB', {
		// 				style: 'decimal',
		// 				maximumFractionDigits: 2,
		// 			}).format(parseFloat(_value.replace(/,/g, '')));
		// 			if (_value.charAt(_value.length - 1) == '.') {
		// 				formattedValue += '.';
		// 			}

		// 			this.sellingInfoForm
		// 				.get('agreedSellingPrice')
		// 				?.setValue(formattedValue as any, {
		// 					emitEvent: false,
		// 					emitModelToViewChange: true,
		// 					emitViewToModelChange: false,
		// 				});
		// 		}
		// 	});

		this.sellingInfoForm$ = this.sellingInfoForm?.valueChanges.subscribe(
			(value) => {
				try {
					localStorage.setItem('oldSellingInfoForm', JSON.stringify(value));
				} catch (error) {}
				this.submitBuild();
			}
		);

		this.conveyancerHasOneYN$ =
			this.conveyancerHasOneYN?.valueChanges.subscribe((value) => {
				if (value == 'Y') {
					if (!this.conveyancerInfoForm.get('conveyancer'))
						this.conveyancerInfoForm.addControl(
							'conveyancer',
							this.buildPersonFormArray()
						);
				} else if (value == 'N') {
					if (this.conveyancerInfoForm.get('conveyancer')) {
						//this.conveyancerInfoForm.get('conveyancer')?.clearValidators();
						this.conveyancerInfoForm.removeControl('conveyancer');
					}
				}

				this.cd.detectChanges();
			});

		this.conveyancerInfoForm$ =
			this.conveyancerInfoForm?.valueChanges.subscribe((value) => {
				try {
					localStorage.setItem('oldConveyancerInfoForm', JSON.stringify(value));
				} catch (error) {}
				this.submitBuild();
			});

		let oldPurchaseInfoForm: any = localStorage.getItem('oldPurchaseInfoForm');
		if (oldPurchaseInfoForm) {
			try {
				oldPurchaseInfoForm = JSON.parse(oldPurchaseInfoForm);
				this.purchaseInfoForm.patchValue(oldPurchaseInfoForm);
			} catch (error) {}
		}

		let oldMortgageInfoForm: any = localStorage.getItem('oldMortgageInfoForm');
		if (oldMortgageInfoForm) {
			try {
				oldMortgageInfoForm = JSON.parse(oldMortgageInfoForm);
				this.mortgageInfoForm.patchValue(oldMortgageInfoForm);
			} catch (error) {}
		}

		let oldSellingInfoForm: any = localStorage.getItem('oldSellingInfoForm');
		if (oldSellingInfoForm) {
			try {
				oldSellingInfoForm = JSON.parse(oldSellingInfoForm);
				this.sellingInfoForm.patchValue(oldSellingInfoForm);
			} catch (error) {}
		}

		let oldConveyancerInfoForm: any = localStorage.getItem(
			'oldConveyancerInfoForm'
		);
		if (oldConveyancerInfoForm) {
			try {
				oldConveyancerInfoForm = JSON.parse(oldConveyancerInfoForm);
				this.conveyancerInfoForm.patchValue(oldConveyancerInfoForm);
			} catch (error) {}
		}
	}

	ngOnDestroy() {
		if (this.buyingPropertyYN$?.unsubscribe)
			this.buyingPropertyYN$.unsubscribe();
		if (this.acceptedOfferYN$?.unsubscribe) this.acceptedOfferYN$.unsubscribe();
		if (this.haveMortgageBrokerYN$?.unsubscribe)
			this.haveMortgageBrokerYN$.unsubscribe();
		if (this.sellingPropertyYN$?.unsubscribe)
			this.sellingPropertyYN$.unsubscribe();
		if (this.conveyancerHasOneYN$?.unsubscribe)
			this.conveyancerHasOneYN$.unsubscribe();
		if (this.agreedSellingPrice$?.unsubscribe)
			this.agreedSellingPrice$.unsubscribe();
		if (this.purchaseInfoForm$?.unsubscribe)
			this.purchaseInfoForm$?.unsubscribe();
		if (this.mortgageInfoForm$?.unsubscribe)
			this.mortgageInfoForm$?.unsubscribe();
		if (this.sellingInfoForm$?.unsubscribe)
			this.sellingInfoForm$?.unsubscribe();
		if (this.conveyancerInfoForm$?.unsubscribe)
			this.conveyancerInfoForm$?.unsubscribe();
	}

	buildAddressForm() {
		return this.fb.group({
			postcode: [environment.postcode, [Validators.required]],
			address: ['', [Validators.required]],
			addressline1: [null],
			addressline2: [null],
			number: [null],
			premise: [null],
			street: [null],
			posttown: [null],
			county: [null],
			UPRN: ['', [Validators.required]],
		});
	}

	buildPersonFormArray() {
		let basePerson = this.fb.group({
			firstName: [environment.firstName, [Validators.required]],
			lastName: [environment.lastName, [Validators.required]],
			email: [
				environment.email,
				[Validators.required, Validators.email],
				//[this.peopleBackend.validEmail()],
			],
			phoneCountryCode: ['+44', [Validators.required]],
			phoneInCountryNumber: [
				'',
				[Validators.required, Validators.pattern('^[1-9]\\d{9}')],
			],
			company: [null],
		});

		return this.fb.array([basePerson], [atLeastOneRequired]);
	}

	buyingOrSellingValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (
				this.buyingPropertyYN?.value == 'N' &&
				this.sellingPropertyYN?.value == 'N'
			) {
				return { buyingOrSelling: true };
			}
			return null;
		};
	}

	submitForm() {
		if (
			this.purchaseInfoForm.valid &&
			(this.buyingPropertyYN?.value == 'N' || this.mortgageInfoForm.valid) &&
			this.sellingInfoForm.valid &&
			this.conveyancerInfoForm.valid &&
			(this.buyingPropertyYN?.value == 'Y' ||
				this.sellingPropertyYN?.value == 'Y')
		) {
			this.submitBuild();
			this.submit.emit();
		}
	}

	submitBuild() {
		if (
			this.purchaseInfoForm.valid &&
			(this.buyingPropertyYN?.value == 'N' || this.mortgageInfoForm.valid) &&
			this.sellingInfoForm.valid &&
			this.conveyancerInfoForm.valid &&
			(this.buyingPropertyYN?.value == 'Y' ||
				this.sellingPropertyYN?.value == 'Y')
		) {
			//form data for put here
			let buyerSeller_data: {
				userType: userType;
				otherBuyersSellers?: any[];
				conveyancers?: any[];
				buying?: {
					mortgageBrokers?: any[];
					preMortgage?: number | undefined;
					requireMortgage?: booleanString;
					agents?: any[];
					address?: string;
					addressline1?: string;
					addressline2?: string;
					number?: string;
					premise?: string;
					street?: string;
					posttown?: string;
					county?: string;
					postCode?: string;
					UPRN?: string;
					agreedPrice?: number;
					offerDate?: string;
				};
				selling?: {
					agents?: any[];
					address?: string;
					addressline1?: string;
					addressline2?: string;
					number?: string;
					premise?: string;
					street?: string;
					posttown?: string;
					county?: string;
					postCode?: string;
					UPRN?: string;
					agreedPrice?: number;
					offerDate?: string;
				};
			} = {
				userType: userType.buyerSeller,
				otherBuyersSellers: [],
				conveyancers: [],
			};

			if (this.buyingPropertyYN?.value == 'Y') {
				buyerSeller_data.buying = {};

				if (this.acceptedOfferYN?.value == 'Y') {
					buyerSeller_data.buying = {
						preMortgage: undefined,
						requireMortgage: this.mortgageNeededYN?.value,
						agents: [
							{
								firstName: (
									this.purchaseInfoForm.get('sellingAgent') as FormArray
								).controls[0].get('firstName')?.value,
								lastName: (
									this.purchaseInfoForm.get('sellingAgent') as FormArray
								).controls[0].get('lastName')?.value,
								email: (
									this.purchaseInfoForm.get('sellingAgent') as FormArray
								).controls[0].get('email')?.value,
								phoneCountryCode: (
									this.purchaseInfoForm.get('sellingAgent') as FormArray
								).controls[0].get('phoneCountryCode')?.value,
								phoneInCountryNumber: (
									this.purchaseInfoForm.get('sellingAgent') as FormArray
								).controls[0].get('phoneInCountryNumber')?.value,
								phone: (
									this.purchaseInfoForm.get('sellingAgent') as FormArray
								).controls[0].get('phone')?.value,
								company: (
									this.purchaseInfoForm.get('sellingAgent') as FormArray
								).controls[0].get('company')?.value,
							},
						],
						address: this.purchaseInfoForm.get('address')?.get('address')
							?.value,
						addressline1: this.purchaseInfoForm
							.get('address')
							?.get('addressline1')?.value,
						addressline2: this.purchaseInfoForm
							.get('address')
							?.get('addressline2')?.value,
						number: this.purchaseInfoForm.get('address')?.get('number')?.value,
						premise: this.purchaseInfoForm.get('address')?.get('premise')
							?.value,
						street: this.purchaseInfoForm.get('address')?.get('street')?.value,
						posttown: this.purchaseInfoForm.get('address')?.get('posttown')
							?.value,
						county: this.purchaseInfoForm.get('address')?.get('county')?.value,
						postCode: this.purchaseInfoForm.get('address')?.get('postcode')
							?.value,
						UPRN: this.purchaseInfoForm.get('address')?.get('UPRN')?.value,
						agreedPrice: 0,
						offerDate: '',
					};
				}

				if (
					this.mortgageNeededYN?.value == 'Y' &&
					this.haveMortgageBrokerYN?.value == 'Y'
				) {
					buyerSeller_data.buying.mortgageBrokers = [
						{
							firstName: (
								this.mortgageInfoForm.get('mortgageBroker') as FormArray
							).controls[0].get('firstName')?.value,
							lastName: (
								this.mortgageInfoForm.get('mortgageBroker') as FormArray
							).controls[0].get('lastName')?.value,
							email: (
								this.mortgageInfoForm.get('mortgageBroker') as FormArray
							).controls[0].get('email')?.value,
							phoneCountryCode: (
								this.mortgageInfoForm.get('mortgageBroker') as FormArray
							).controls[0].get('phoneCountryCode')?.value,
							phoneInCountryNumber: (
								this.mortgageInfoForm.get('mortgageBroker') as FormArray
							).controls[0].get('phoneInCountryNumber')?.value,
							phone: (
								this.mortgageInfoForm.get('mortgageBroker') as FormArray
							).controls[0].get('phone')?.value,
							company: (
								this.mortgageInfoForm.get('mortgageBroker') as FormArray
							).controls[0].get('company')?.value,
						},
					];
				}
			}

			if (this.sellingPropertyYN?.value == 'Y') {
				buyerSeller_data.selling = {
					agents: [
						{
							firstName: (
								this.sellingInfoForm.get('sellingAgent') as FormArray
							).controls[0].get('firstName')?.value,
							lastName: (
								this.sellingInfoForm.get('sellingAgent') as FormArray
							).controls[0].get('lastName')?.value,
							email: (
								this.sellingInfoForm.get('sellingAgent') as FormArray
							).controls[0].get('email')?.value,
							phoneCountryCode: (
								this.sellingInfoForm.get('sellingAgent') as FormArray
							).controls[0].get('phoneCountryCode')?.value,
							phoneInCountryNumber: (
								this.sellingInfoForm.get('sellingAgent') as FormArray
							).controls[0].get('phoneInCountryNumber')?.value,
							phone: (
								this.sellingInfoForm.get('sellingAgent') as FormArray
							).controls[0].get('phone')?.value,
							company: (
								this.sellingInfoForm.get('sellingAgent') as FormArray
							).controls[0].get('company')?.value,
						},
					],
					address: this.sellingInfoForm.get('address')?.get('address')?.value,
					addressline1: this.sellingInfoForm.get('address')?.get('addressline1')
						?.value,
					addressline2: this.sellingInfoForm.get('address')?.get('addressline2')
						?.value,
					number: this.sellingInfoForm.get('address')?.get('number')?.value,
					premise: this.sellingInfoForm.get('address')?.get('premise')?.value,
					street: this.sellingInfoForm.get('address')?.get('street')?.value,
					posttown: this.sellingInfoForm.get('address')?.get('posttown')?.value,
					county: this.sellingInfoForm.get('address')?.get('county')?.value,
					postCode: this.sellingInfoForm.get('address')?.get('postcode')?.value,
					UPRN: this.sellingInfoForm.get('address')?.get('UPRN')?.value,
					agreedPrice: 0,
					offerDate: '',
				};
			}

			if (this.conveyancerHasOneYN?.value == 'Y') {
				buyerSeller_data.conveyancers = [
					{
						firstName: (
							this.conveyancerInfoForm.get('conveyancer') as FormArray
						).controls[0].get('firstName')?.value,
						lastName: (
							this.conveyancerInfoForm.get('conveyancer') as FormArray
						).controls[0].get('lastName')?.value,
						email: (
							this.conveyancerInfoForm.get('conveyancer') as FormArray
						).controls[0].get('email')?.value,
						phoneCountryCode: (
							this.conveyancerInfoForm.get('conveyancer') as FormArray
						).controls[0].get('phoneCountryCode')?.value,
						phoneInCountryNumber: (
							this.conveyancerInfoForm.get('conveyancer') as FormArray
						).controls[0].get('phoneInCountryNumber')?.value,
						phone: (
							this.conveyancerInfoForm.get('conveyancer') as FormArray
						).controls[0].get('phone')?.value,
						company: (
							this.conveyancerInfoForm.get('conveyancer') as FormArray
						).controls[0].get('company')?.value,
					},
				];
			}
			this.purchaseInfoForm.markAsUntouched();
			this.mortgageInfoForm.markAsUntouched();
			this.sellingInfoForm.markAsUntouched();
			this.conveyancerInfoForm.markAsUntouched();

			this.purchaseInfoForm.markAsPristine();
			this.mortgageInfoForm.markAsPristine();
			this.sellingInfoForm.markAsPristine();
			this.conveyancerInfoForm.markAsPristine();

			this.data.emit(buyerSeller_data);
		} else {
			this.data.emit(null);
		}
	}
}
