<div class="container">
	<h1 class="header full-row">Sort Email</h1>
	<app-loading-tombstone
		class="full-row"
		*ngIf="loading"></app-loading-tombstone>
	<mat-card
		appearance="outlined"
		class="full-row"
		*ngIf="(email?.posableProperties || []).length > 0">
		<mat-card-content>
			<h2>Posable Properties</h2>
			<div
				class="posable-property"
				*ngFor="let property of email?.posableProperties">
				<img
					[matTooltip]="property.nickName || ''"
					style="width: 1.5rem"
					[ngClass]="{
						'unknown-prop': property.id == 'unknown',
						'yellow-prop': propColor(property) == 'yellow'
					}"
					[src]="logoURL(property)"
					alt="house" />
				<a
					[routerLink]="[
						'/',
						'chain',
						property.chainId,
						'property',
						property.id
					]">
					<div>{{ property?.nickName }} {{ property?.address }}</div>
					<!-- <div>{{ cleanAddress(property.UPRN || "") }}</div> -->
				</a>
				<div style="flex-grow: 1"></div>
				<div>
					<button mat-button (click)="topSelectProperty(property)">
						select
					</button>
				</div>
			</div>
		</mat-card-content>
	</mat-card>
	<mat-card
		appearance="outlined"
		class="full-row"
		*ngIf="(email?.properties || []).length > 0">
		<mat-card-content>
			<h2>Properties</h2>
			<div *ngFor="let property of email?.properties">
				<img
					[matTooltip]="property.nickName || ''"
					style="width: 1.5rem"
					[ngClass]="{
						'unknown-prop': property.id == 'unknown',
						'yellow-prop': propColor(property) == 'yellow'
					}"
					[src]="logoURL(property)"
					alt="house" />
				<a
					[routerLink]="[
						'/',
						'chain',
						property.chainId,
						'property',
						property.id
					]">
					<div>{{ property?.nickName }} {{ property?.address }}</div>
					<!-- <div>{{ cleanAddress(property.UPRN || "") }}</div> -->
				</a>
			</div>
		</mat-card-content>
	</mat-card>
	<mat-card appearance="outlined" class="full-row" *ngIf="!loading && email">
		<mat-card-content>
			<h2>
				{{ email.subject }}
			</h2>
			<div class="to-from-row">
				<div>
					<div
						class="to-from-line"
						(dblclick)="setAsSpoof(email.from)"
						[matTooltip]="email.from.userEmail">
						From: <span class="name">{{ email.from.name }}</span>
					</div>
					<div class="to-from-line">
						To:
						<span
							class="name"
							*ngFor="let to of email?.to; let i = index"
							(dblclick)="setAsSpoof(to)"
							[matTooltip]="to.userEmail"
							>{{ to?.name }}</span
						>
					</div>
					<div class="to-from-line" *ngIf="(email?.cc || []).length > 0">
						CC:
						<span
							class="name"
							*ngFor="let to of email?.cc; let i = index"
							(dblclick)="setAsSpoof(to)"
							[matTooltip]="to.userEmail"
							>{{ to?.name }}</span
						>
					</div>
				</div>
				<div>
					<div class="to-from-line" style="text-align: end">
						{{ email.sent | date : "medium"
						}}<mat-icon
							style="
								font-size: 1rem;
								height: 1rem;
								width: 1rem;
								margin-top: 0rem;
							"
							>send</mat-icon
						>
					</div>
					<div
						class="to-from-line"
						*ngIf="email.opens[0]"
						style="text-align: end">
						{{ email.opens[0].timestamp | date : "medium"
						}}<mat-icon
							style="
								font-size: 1rem;
								height: 1rem;
								width: 1rem;
								margin-top: 0rem;
							"
							>visibility</mat-icon
						>
					</div>
				</div>
			</div>
			<pre>{{ email.text }}</pre>
		</mat-card-content>
	</mat-card>
	<mat-card class="full-row" *ngIf="(email?.attachments || []).length > 0">
		<mat-card-content>
			<h2>Attachments</h2>
			<div *ngFor="let attachment of email?.attachments">
				{{ attachment?.filename }}
			</div>
		</mat-card-content>
	</mat-card>
	<mat-card class="open-click" *ngIf="(email?.clicks || []).length > 0">
		<mat-card-content>
			<h2>Clicks</h2>
			<div class="item-row" *ngFor="let click of email?.clicks">
				<span>{{ click?.timestamp | date : "medium" }}</span>
				<span>{{ click?.link }}</span>
			</div>
		</mat-card-content>
	</mat-card>
	<mat-card class="open-click" *ngIf="(email?.opens || []).length > 0">
		<mat-card-content>
			<h2>Opens</h2>
			<div class="item-row" *ngFor="let open of email?.opens">
				<span>
					{{ open?.timestamp | date : "medium" }}
				</span>
				<span>{{ open?.ip }}</span>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card class="full-row">
		<mat-card-content>
			<h2>ML Sort Results : {{ email?.mlDate | date : "medium" }}</h2>
			<div>
				<button mat-raised-button color="primary" (click)="reRunMLSort()">
					Re-Run ML Sort
				</button>
			</div>
			<div>Task Updates:</div>
			<div
				class="item-row"
				style="display: block"
				*ngFor="let result of email?.mlResults?.tasks_subtasks || []">
				<div>{{ property(result.id)?.address }}</div>
				<span>{{ task(result.id)?.title }} => {{ result?.title }}</span>
				<span>{{ task(result.id)?.status }} => {{ result?.state }}</span>
				<span style="margin-left: 1rem"
					>{{ task(result.id)?.result }} => {{ result?.result }}</span
				>
				<mat-checkbox [(ngModel)]="result.confirmed">Confirm</mat-checkbox>
				<mat-divider></mat-divider>
			</div>
			<div>New Inquires:</div>
			<div
				class="item-row"
				style="display: block"
				*ngFor="let result of email?.mlResults?.new_inquiries || []">
				<div>{{ property(result.taskId)?.address }}</div>
				<div>{{ result?.inquiry }}</div>
				<span>{{ result?.userTypeBeingAsked }}</span>
				<span style="margin-left: 1rem"
					>{{ task(result.taskId)?.title }} => {{ result?.taskTitle }}</span
				>
				<mat-checkbox [(ngModel)]="result.confirmed">Confirm</mat-checkbox>
				<mat-divider></mat-divider>
			</div>

			<div>Inquiry Reposes:</div>
			<div
				class="item-row"
				style="display: block"
				*ngFor="let result of email?.mlResults?.inquiry_responses || []">
				<div>{{ property(result.taskId)?.address }}</div>
				<div style="margin-left: 1rem">
					{{ task(result.taskId)?.title }} => {{ result?.taskTitle }}
				</div>
				<div>{{ result?.response }}</div>
				<div>{{ result?.status }}</div>
				<mat-checkbox [(ngModel)]="result.confirmed">Confirm</mat-checkbox>
				<mat-divider></mat-divider>
			</div>

			<div>
				<button mat-raised-button color="primary" (click)="submitReview()">
					Submit Review
				</button>
			</div>
		</mat-card-content>
	</mat-card>

	<!-- <pre>{{ email | json }}</pre> -->
</div>
<div class="right-side">
	<mat-card>
		<mat-card-content>
			<div class="chain-view">
				<div class="links" *ngFor="let link of chain?.links; let i = index">
					<div>
						<!-- <pre>{{ link | json }}</pre> -->
						<div class="buyerSeller" *ngIf="link.linkType == 'buyerSeller'">
							<div>Buyer/Seller</div>
							<div
								class="person"
								*ngFor="let person of link.persons; let i = index">
								{{ person.email }}
							</div>
						</div>
						<div class="property" *ngIf="link.linkType == 'property'">
							<div>Property</div>
							<div>{{ link.nickName }}</div>
							<!-- <div>{{ link.UPRN }}</div> -->
							<div
								class="task"
								*ngFor="let mileStone of link.children; let i = index">
								<span
									[ngClass]="{
										'task-not-started':
											mileStone.status == taskStatus.notStarted,
										'task-in-progress':
											mileStone.status == taskStatus.inProgress,
										'task-completed': mileStone.status == taskStatus.completed,
										'task-disabled': mileStone.status == taskStatus.disabled,
										'task-canceled': mileStone.status == taskStatus.cancelled
									}">
									{{ mileStone.title }}
								</span>
								<div
									class="task"
									*ngFor="let task of mileStone.children; let i = index">
									<span
										[ngClass]="{
											'task-not-started': task.status == taskStatus.notStarted,
											'task-in-progress': task.status == taskStatus.inProgress,
											'task-completed': task.status == taskStatus.completed,
											'task-disabled': task.status == taskStatus.disabled,
											'task-canceled': task.status == taskStatus.cancelled
										}">
										{{ task.title }}
									</span>
									<button
										*ngIf="
											task.status == taskStatus.notStarted &&
											task.children.length == 0 &&
											canEditTask(task)
										"
										mat-raised-button
										color="primary"
										(click)="taskState(task, taskStatus.inProgress)">
										In Progress
									</button>
									<button
										*ngIf="
											task.status == taskStatus.inProgress &&
											task.children.length == 0 &&
											canEditTask(task)
										"
										mat-raised-button
										color="accent"
										(click)="taskState(task, taskStatus.completed)">
										Completed
									</button>
									<div
										class="task"
										*ngFor="let subTask of task.children; let i = index">
										<span
											[ngClass]="{
												'task-not-started':
													subTask.status == taskStatus.notStarted,
												'task-in-progress':
													subTask.status == taskStatus.inProgress,
												'task-completed':
													subTask.status == taskStatus.completed,
												'task-disabled': subTask.status == taskStatus.disabled,
												'task-canceled': subTask.status == taskStatus.cancelled
											}">
											{{ subTask.title }}
										</span>
										<button
											*ngIf="
												((subTask.buttons &&
													subTask.status != taskStatus.inProgress) ||
													subTask.status == taskStatus.notStarted) &&
												canEditTask(subTask)
											"
											mat-raised-button
											color="primary"
											(click)="taskState(subTask, taskStatus.inProgress)">
											In Progress
										</button>
										<button
											*ngIf="
												((subTask.buttons &&
													subTask.status != taskStatus.completed) ||
													subTask.status == taskStatus.inProgress) &&
												canEditTask(subTask)
											"
											mat-raised-button
											color="accent"
											(click)="taskState(subTask, taskStatus.completed)">
											Completed
										</button>
										<button
											*ngIf="
												(subTask.status == taskStatus.disabled ||
													subTask.status == taskStatus.completed ||
													subTask.status == taskStatus.cancelled) &&
												canEditTask(subTask) &&
												!subTask.buttons
											"
											mat-button
											color="accent"
											(click)="subTask.buttons = true">
											...
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="task"></div>
				</div>
			</div>
		</mat-card-content>
	</mat-card>
</div>
